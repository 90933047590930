import * as React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import { useRoutes } from "react-router-dom";
import { BaseLayout } from "./layouts/BaseLayout";
import { SettingsProvider } from "./app/context/settingsContext";
import { AuthProvider } from "./app/context/auth-context";
import routes from "./router";

const App = () => {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <AuthProvider>
        <ParallaxProvider>
          <BaseLayout>{content}</BaseLayout>
        </ParallaxProvider>
      </AuthProvider>
    </SettingsProvider>
  );
};

export default App;
