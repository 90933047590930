import { createSlice } from "@reduxjs/toolkit";
import { LOADING } from "app/utils/constants/others";
import { toast } from "react-toastify";
import {
  updateProfile,
  uploadMedia,
  getUserEvaluation,
  getProfileAndAddIfNotFound,
  listNotifications,
  readNotification,
  sendNotification,
} from "./reducers";
import { initialState } from "./state";
import { updatedLoggedUserState } from "./helpers";

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setImportedColelction: (state, action) => {
      state.userAssets.importedCollection = action.payload;
    },
    updateCollections: (state, action) => {
      state.userAssets.collections = action.payload;
    },
    setNftOwnership: (state, action) => {
      const updatedNfts = state.userAssets.importedCollection.collection_nfts.map((nft) => {
        if (action.payload.nftId === nft.id) {
          return { ...nft, ownerIsPublic: action.payload.ownerIsPublic };
        }
        return nft;
      });
      state.userAssets.importedCollection.collection_nfts = updatedNfts;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileAndAddIfNotFound.pending, (state) => {
      state.loading.getProfile = LOADING.PENDING;
    });
    builder.addCase(getProfileAndAddIfNotFound.fulfilled, (state, action) => {
      const updatedState = updatedLoggedUserState(state, action.payload);
      return updatedState;
    });
    builder.addCase(getProfileAndAddIfNotFound.rejected, (state, action) => {
      state.error = action.error;
      state.loading.getProfile = LOADING.FAILED;
    });

    builder.addCase(updateProfile.pending, (state) => {
      state.loading.updateProfile = LOADING.PENDING;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      toast.success("Profile successfully updated.");
      return {
        ...state,
        loading: { ...state.loading, updateProfile: LOADING.SUCCEEDED },
        profileInfo: {
          ...state.profileInfo,
          ...action.payload,
        },
      };
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.error = action.error;
      state.loading.updateProfile = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(uploadMedia.pending, (state) => {
      state.loading.uploadMedia = LOADING.PENDING;
    });
    builder.addCase(uploadMedia.fulfilled, (state, action) => {
      const baseUrl = import.meta.env.VITE_REACT_APP_API_HOST;
      state.profileMedia[action.payload.field] = baseUrl + action.payload.url;
      state.loading.uploadMedia = LOADING.SUCCEEDED;
      toast.success("Image successfully uploaded.");
    });
    builder.addCase(uploadMedia.rejected, (state, action) => {
      state.error = action.error;
      state.loading.uploadMedia = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(getUserEvaluation.pending, (state) => {
      state.loading.getEvaluation = LOADING.PENDING;
    });
    builder.addCase(getUserEvaluation.fulfilled, (state, action) => {
      state.userEvaluation = action.payload;
      state.loading.getEvaluation = LOADING.SUCCEEDED;
    });
    builder.addCase(getUserEvaluation.rejected, (state, action) => {
      state.error = action.error;
      state.loading.getEvaluation = LOADING.FAILED;
    });

    builder.addCase(listNotifications.pending, (state) => {
      state.loading.notification = LOADING.PENDING;
    });
    builder.addCase(listNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload?.[0];
      state.unreadNotificationCount = action.payload?.[1];
    });

    builder.addCase(readNotification.fulfilled, (state, action) => {
      state.notifications = state.notifications.map((notif: any) => {
        if (notif.id === action.payload) {
          return {
            ...notif,
            is_read: true,
          };
        }
        return notif;
      });
      state.unreadNotificationCount -= 1;
    });
  },
});

export {
  updateProfile,
  uploadMedia,
  getUserEvaluation,
  getProfileAndAddIfNotFound,
  listNotifications,
  readNotification,
  sendNotification,
};

export const { updateCollections, setNftOwnership, setImportedColelction } = userSlice.actions;

export default userSlice.reducer;
