import { createAsyncThunk } from "@reduxjs/toolkit";
import { ViewinKeyManager } from "app/utils/helpers/viewingKeyManager";
import { Snip721 } from "secretjs-service/dist/esm/contracts";
import { AccessProps } from "secretjs-service/dist/esm/types/Snip721Types";
import { createSnip721ViewingKey } from "./helpers";
import { mint as mintNft } from "services/nfts/nftService";
import { getKeplr } from "app/utils/keplr";
import { MediaFile, Metadata } from "secretjs/dist/extensions/snip721/types";

export const listNfts = createAsyncThunk<
  any,
  {
    collection_address: string;
    all?: boolean;
    notify?: boolean;
    isPublic?: boolean;
  }
>("listNfts", async ({ collection_address, all, notify = true, isPublic = false }) => {
  const { address: walletAddress } = await getKeplr();
  const snip721 = new Snip721(walletAddress);
  let vk = await ViewinKeyManager.getKey(collection_address);

  if (!vk && !isPublic) {
    const res = await createSnip721ViewingKey(collection_address, walletAddress);
    vk = res;
  }
  const tokensIds = await snip721.getContractTokens(collection_address, vk, all);
  const promises = tokensIds.map((tokenId: string) =>
    snip721.getNftDossier(collection_address, tokenId, vk)
  );

  const res = await Promise.all(promises);
  // eslint-disable-next-line
  //@ts-ignore
  const nfts = res.map((dossier, i) => {
    // eslint-disable-next-line
    const { public_metadata, token_approvals, inventory_approvals, owner_is_public } = dossier;
    const { extension } = public_metadata as Metadata;

    return {
      id: tokensIds[i],
      name: extension?.name ?? "",
      description: extension?.description ?? "",
      image: (extension?.media as MediaFile[])[0].url ?? "",
      approvals: token_approvals,
      collectionCreator: dossier?.mint_run_info?.collection_creator,
      owner: dossier?.owner,
      ownerIsPublic: owner_is_public,
      inventoryApprovals: inventory_approvals,
    };
  });

  return {
    collection_address,
    nfts,
    notify,
  };
});

export const mint = createAsyncThunk<string[], any>("mint", async ({ collectionAddress, nfts }) => {
  const { address } = await getKeplr();
  const ids: string[] = await mintNft(address, collectionAddress, nfts);
  return ids;
});

export const approveNftAccess = createAsyncThunk<string, AccessProps>(
  "approveNftAccess",
  async ({ spender, collection_address, tokenId, walletAddress }) => {
    const snip721 = new Snip721(walletAddress);
    await snip721.approve(spender, collection_address, tokenId);

    return tokenId;
  }
);

export const revokeNftAccess = createAsyncThunk<string, AccessProps>(
  "revokeNftAccess",
  async ({ spender, collection_address, tokenId, walletAddress }) => {
    const snip721 = new Snip721(walletAddress);
    await snip721.revoke(spender, collection_address, tokenId);

    return tokenId;
  }
);

export const setOwnershipPrivate = createAsyncThunk<any, any>(
  "setOwnershipPrivate",
  async ({ walletAddress, contractAddress }) => {
    const snip721 = new Snip721(walletAddress);
    await snip721.setOwnershipPrivate(contractAddress);
  }
);

export const makeNftSwappable = createAsyncThunk<any, any>(
  "makeTokenSwappable",
  async ({ walletAddress, contractAddress, address, tokenId, viewOwner, transfer }) => {
    const snip721 = new Snip721(walletAddress);
    await snip721.setWhitelistedApproval({
      contractAddress,
      address,
      tokenId,
      viewOwner,
      transfer,
    });
  }
);

export const setTokensOwnerPublic = createAsyncThunk<
  void,
  { walletAddress: string; contractAddress: string; viewOwner: "all" | "none" }
>("setTokensOwnerPublic", async ({ contractAddress, walletAddress, viewOwner }) => {
  const snip721 = new Snip721(walletAddress);
  await snip721.setGlobalApproval({ contractAddress, viewOwner });
});

export const makeTokenSwappable = createAsyncThunk<any, any>(
  "makeTokenSwappable",
  async ({ walletAddress, contractAddress, address, tokenId, viewOwner, transfer }) => {
    const snip721 = new Snip721(walletAddress);
    const tx = await snip721.setWhitelistedApproval({
      contractAddress,
      address,
      tokenId,
      viewOwner,
      transfer,
    });
  }
);

export const setGlobalApproval = createAsyncThunk<void, any>(
  "setGlobalApproval",
  async ({ walletAddress, contractAddress, viewOwner, tokenId }) => {
    const snip721 = new Snip721(walletAddress);
    await snip721.setGlobalApproval({
      contractAddress,
      viewOwner,
      tokenId,
    });
  }
);
