import { ViewinKeyManager } from "app/utils/helpers/viewingKeyManager";
import { Snip721 } from "secretjs-service/dist/esm/contracts";
import { addressValidator } from "app/utils/helpers";

export const createSnip721ViewingKey = async (
  collection_address: string,
  walletAddress: string
) => {
  if (!addressValidator(collection_address)) {
    throw new Error("invalid address given!");
  }

  const existingKey = await ViewinKeyManager.getKey(collection_address);
  if (existingKey) {
    return existingKey;
  }

  const snip721 = new Snip721(walletAddress);
  const key = await snip721.createViewingKey(collection_address);
  ViewinKeyManager.add(walletAddress, collection_address, key);

  return key;
};
