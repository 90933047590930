import { API } from "app/libs/api";
import { AuctionDocumentInfo } from "app/types/auctions";

export const getAuctionDocument = async (auctionAddress: string): Promise<AuctionDocumentInfo> => {
  const { data } = await API.get(`/index/auctions/${auctionAddress}`);
  return data;
};

export const indexAuction = async (auctionAddress: string) => {
  const res = await API.post(`/index/auctions/${auctionAddress}`);
  return res;
};
