import { getKeplr } from "app/utils/keplr";
import { getFromLocalStorage, saveToLocalStorage } from "./localstorage";

interface Key {
  contract: string;
  value: string;
}

interface Account {
  address: string;
  keys: Array<Key>;
}

export class ViewinKeyManager {
  static getAccount(walletAddress: string) {
    const accounts = getFromLocalStorage<Array<Account>>("acti-keys");
    if (!accounts) return null;

    const account = accounts.find((a: Account) => a.address === walletAddress);
    return account;
  }

  static addAccount(walletAddress: string) {
    const account = {
      address: walletAddress,
      keys: [],
    };

    const accounts = getFromLocalStorage<Array<Account>>("acti-keys");
    if (accounts) {
      accounts.push(account);
      saveToLocalStorage("acti-keys", accounts);
    } else {
      saveToLocalStorage("acti-keys", [account]);
    }

    return account;
  }

  static async getKey(contract: string) {
    const { address } = await getKeplr();
    const account = ViewinKeyManager.getAccount(address);
    if (!account) throw new Error("No accounts found! Please authenticate !");

    const key = account.keys.find((k: Key) => k.contract === contract);
    return key?.value;
  }

  static add(walletAddress: string, contract: string, key: string) {
    if (!key) throw new Error("Empty or undefined key cannot be added");

    let account = ViewinKeyManager.getAccount(walletAddress);
    if (!account) {
      account = ViewinKeyManager.addAccount(walletAddress);
    }

    const accounts = getFromLocalStorage<Array<Account>>("acti-keys");
    // eslint-disable-next-line
    accounts.map((a) => {
      if (a.address === walletAddress) {
        a.keys.push({ contract, value: key });
      }
    });

    saveToLocalStorage("acti-keys", accounts);
  }

  static set(walletAddress: string, contract: string, key: string) {
    if (!key) throw new Error("Empty or undefined key cannot be added");

    const accounts = getFromLocalStorage<Array<Account>>("acti-keys");
    // eslint-disable-next-line
    accounts.map((a) => {
      if (a.address === walletAddress) {
        // eslint-disable-next-line
        a.keys.map((k) => {
          if (k.contract === contract) {
            k.value = key;
          }
        });
      }
    });

    saveToLocalStorage("acti-keys", accounts);
  }

  static remove(walletAddress: string, contract: string) {
    const accounts = getFromLocalStorage<Array<Account>>("acti-keys");
    accounts?.map((a) => {
      if (a.address === walletAddress) {
        // eslint-disable-next-line
        a.keys.map((k, i) => {
          if (k.contract === contract) {
            a.keys.splice(i, 1);
          }
        });
      }
    });

    saveToLocalStorage("acti-keys", accounts);
  }
}
