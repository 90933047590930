import * as React from "react";
import { CircularProgress, Box } from "@mui/material";

interface Props {
  height?: string;
}

export const Loading = (props: Props) => {
  const { height = "100%" } = props;
  return (
    <Box
      sx={{
        height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
