import { API } from "app/libs/api";

export const checkOwnedAuctions = (auctions: any, target_auction: string) => {
  const activeOwned = auctions?.active?.as_seller?.filter(
    (auc: any) => auc.address === target_auction
  );
  const closedOwned = auctions?.closed?.as_seller?.filter(
    (auc: any) => auc.address === target_auction
  );

  const isOwner = !!(activeOwned.length > 0 || closedOwned.length > 0);
  return isOwner;
};

export const listFailedAuctionsAddresses = async () => {
  const {
    data: { data },
  } = await API.get("auctions?pagination[limit]=-1");
  return data;
};
export const filterUnverifiedAuctions = (all, failed) => {
  const res = all.filter(({ address }) => {
    // eslint-disable-next-line
    for (let i = 0; i < failed.length; i++) {
      if (address === failed[i].attributes.title) {
        return false;
      }
    }
    return true;
  });

  return res;
};
