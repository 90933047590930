/* eslint-disable */

import axios from "axios";
import { getItemFromLS } from "app/utils/helpers/localstorage";
import { getKeplr } from "app/utils/keplr";

interface AuthLS {
  address: string;
  jwt: string;
}

const getJwtToken = async (): Promise<string> => {
  const { address } = await getKeplr();
  const currentJwts: AuthLS[] = getItemFromLS("acti-jwt");

  const existing: AuthLS = currentJwts?.find((item: AuthLS) => item.address === address);

  return existing ? existing.jwt : "";
};

export const API = axios.create({
  baseURL: `${import.meta.env.VITE_REACT_APP_API_HOST}/api`,
});

export const API_PUBLIC = axios.create({
  baseURL: `${import.meta.env.VITE_REACT_APP_API_HOST}/api`,
});

API.interceptors.request.use(async (config) => {
  const token = await getJwtToken();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
