import { RouteObject } from "react-router";

// nested routes
import dashboardRoutes from "./routes/dashboard";
import profileRoutes from "./routes/profile";
import marketplaceRoutes from "./routes/marketplace";

// normal routes
import {
  homeRoute,
  tokenSwapRoute,
  createAuctionRoute,
  roadmapRoute,
  learningPortalRoute,
  errorRoute,
  actistudioRoute,
  actiswapRoute,
} from "./routes/others";

const routes: RouteObject[] = [
  homeRoute,
  tokenSwapRoute,
  createAuctionRoute,
  learningPortalRoute,
  roadmapRoute,
  errorRoute,
  dashboardRoutes,
  profileRoutes,
  marketplaceRoutes,
  actistudioRoute,
  actiswapRoute,
];

export default routes;
