import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, IconButton, Stack, Card } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { LOADING } from "app/utils/constants/others";
import { useAppDispatch, useAppSelector } from "features/store";
import { sendNotification } from "features/users";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Modal } from "components/Modal";
import { Success } from "components/Success";
import { API } from "app/libs/api";
import {
  acceptOffer,
  rejectOffer,
  removeOffer,
  updateSentOffers,
  updateReceivedOffers,
} from "features/swap";
import { listCollections } from "features/collections";
import { Toolbar } from "../../swap/Pool/Toolbar";
import { OfferedNFTs } from "./OfferedNFTs";
import { WantedNFTs } from "./WantedNFTs";

const cardStyle = {
  flex: 1,
};
export const OfferDetails = () => {
  const [nftsApprovedForSwap, setNftsApprovedForSwap] = React.useState(0);
  const [isSuccessfullyAccepted, setIsSuccessfullyAccepted] = React.useState(false);
  const navigate = useNavigate();
  const {
    state: { offer, received },
  } = useLocation();

  const { id } = offer;
  const { loading } = useAppSelector((s) => s.swap);
  const { walletAddress } = useAppSelector((s) => s.auth);
  const { collections } = useAppSelector((s) => s.collections);
  const dispatch = useAppDispatch();

  const updateOffersList = (offerId: string) => {
    if (received) {
      dispatch(updateReceivedOffers(offerId));
    } else {
      dispatch(updateSentOffers(offerId));
    }
  };

  const handleAcceptOffer = async () => {
    const { sender } = offer;
    const { data } = await API.get(`collections/${sender}`);
    const recievedCollection = data.collections.find(
      (col: any) => col.collection_address === offer.offered_nfts[0].token_addr
    );

    const sentCollection = collections.find(
      (col: any) => col.collection_address === offer.wanted_nfts[0].token_addr
    );
    await dispatch(
      acceptOffer({ walletAddress, sender, offerId: id, recievedCollection, sentCollection })
    );
    updateOffersList(id);
    setIsSuccessfullyAccepted(true);

    const notification = {
      message: "Swap offer accepted.",
      url: "/profile/assets",
    };

    await dispatch(sendNotification({ address: sender, notification }));
  };

  const handleRejectOffer = async () => {
    const { sender } = offer;
    await dispatch(rejectOffer({ walletAddress, offerId: id }));
    updateOffersList(id);
    navigate("/acti-swap/offers/lists/received");

    const notification = {
      message: "Swap offer rejected.",
      url: "",
    };

    dispatch(sendNotification({ address: sender, notification }));
  };

  const handleRemoveOffer = async () => {
    dispatch(removeOffer({ walletAddress, offerId: id }))
      .unwrap()
      .then(() => {
        dispatch(updateSentOffers(id));
        navigate("/acti-swap/offers/lists/received");
      });
  };

  useEffect(() => {
    dispatch(listCollections(walletAddress));
  }, []);
  return (
    <>
      <Box display="flex" alignItems="center" gap={1} mb={2}>
        <IconButton color="primary" onClick={() => navigate("/acti-swap/offers/lists/received")}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <Typography variant="h6" color="text.secondary">
          Back
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={{
          xs: received ? "column" : "column-reverse",
          md: received ? "row" : "row-reverse",
        }}
        gap={2}
        sx={{ maxWidth: "100%" }}
      >
        <Card sx={cardStyle} variant="outlined">
          <Toolbar
            title={received ? "Wanted" : "Offered"}
            description={received ? "NFTs i will send" : "NFTs i will receive"}
          />
          <WantedNFTs nfts={offer?.wanted_nfts} setNftsApprovedForSwap={setNftsApprovedForSwap} />
        </Card>
        <Card sx={cardStyle} variant="outlined">
          <Toolbar
            title={!received ? "Wanted" : "Offered"}
            description={!received ? "NFTs i will send" : "NFTs i will receive"}
          />
          <OfferedNFTs nfts={offer?.offered_nfts} />
        </Card>
      </Box>
      <Stack spacing={1} direction="row" justifyContent="end" mt={1}>
        {received ? (
          <>
            {" "}
            <LoadingButton
              onClick={handleRejectOffer}
              loading={loading.rejectOffer === LOADING.PENDING}
              color="error"
            >
              Reject offer
            </LoadingButton>
            <LoadingButton
              onClick={handleAcceptOffer}
              loading={loading.acceptOffer === LOADING.PENDING}
              variant="outlined"
              color="success"
              disabled={nftsApprovedForSwap !== offer?.wanted_nfts.length}
            >
              Accept offer
            </LoadingButton>
          </>
        ) : (
          <LoadingButton
            onClick={handleRemoveOffer}
            loading={loading.removeOffer === LOADING.PENDING}
            variant="outlined"
            color="secondary"
          >
            Cancel offer
          </LoadingButton>
        )}
      </Stack>
      <Modal
        open={isSuccessfullyAccepted}
        title="Offer successfully accepted !"
        okButtonLabel="View assets"
        handleOk={() => navigate("/profile/assets")}
        cancelButtonLabel="Back"
        handleCancel={() => navigate("/acti-swap/offers/lists/received")}
      >
        <Stack alignItems="center" spacing={2}>
          <Success />
          <Typography variant="body2">
            You will find your new assets under Profile &gt; Assets .
          </Typography>
        </Stack>
      </Modal>
    </>
  );
};
