import { createAsyncThunk } from "@reduxjs/toolkit";
import { ViewinKeyManager } from "app/utils/helpers/viewingKeyManager";
import { AuctionContract, FactoryContract } from "secretjs-service/dist/esm/contracts";
import { AUCTION_FACTORY_ADDRESS } from "secretjs-service/dist/esm/addresses";
import { Auction } from "secretjs-service/dist/esm/types/FactoryContractTypes";
import { coinConvert } from "secretjs-service/dist/esm/utils";
import { getKeplr } from "app/utils/keplr";
import { API } from "app/libs/api";
import {
  checkOwnedAuctions,
  listFailedAuctionsAddresses,
  filterUnverifiedAuctions,
} from "./helpers";
import {
  ChangeMinBidInputValues,
  PlaceBidInputValues,
  VerifyInputValues,
} from "app/types/auctions";
import { PlacedBidModel } from "app/models/placedBid";

export const listMyAuctions = createAsyncThunk("listMyAuctions", async () => {
  const { address: walletAddress } = await getKeplr();
  const factoryContract = new FactoryContract(walletAddress);
  const vk = (await ViewinKeyManager.getKey(AUCTION_FACTORY_ADDRESS)) as string;
  const myAuctions = await factoryContract.listMyAuctions(vk);
  return myAuctions;
});

export const listUnverifiedAuctions = createAsyncThunk("listUnverifiedAuctions", async () => {
  const factoryContract = new FactoryContract();
  const unverified = await factoryContract.listUnverifiedAuctions();

  const failedAuctionsAddresses = await listFailedAuctionsAddresses();
  const res = filterUnverifiedAuctions(unverified, failedAuctionsAddresses);

  return res;
});

export const createAuction = createAsyncThunk<string, { auction: Auction }>(
  "createAuction",
  async ({ auction }: { auction: Auction }) => {
    const { address: walletAddress } = await getKeplr();
    const factoryContract = new FactoryContract(walletAddress);
    const res = await factoryContract.createAuction(auction);
    if (!res.arrayLog) throw new Error(res.rawLog);

    const auctionAddress = (res as any).arrayLog.find(
      (log: { key: string }) => log.key === "auction_address"
    ).value;

    return auctionAddress;
  }
);

export const getAuctionInfo = createAsyncThunk("getAuctionInfo", async (auctionAddress: string) => {
  const auctionContract = new AuctionContract();
  const info = await auctionContract.getInfo(auctionAddress);
  return info;
});

export const isAuctionOwner = createAsyncThunk("isOwner", async (auctionAddress: string) => {
  const { address: walletAddress } = await getKeplr();
  const factoryContract = new FactoryContract(walletAddress);
  const vk = (await ViewinKeyManager.getKey(AUCTION_FACTORY_ADDRESS)) as string;
  const myAuctions = await factoryContract.listMyAuctions(vk);
  const isOwner = checkOwnedAuctions(myAuctions, auctionAddress);
  return isOwner;
});

export const isAuctionHasBids = createAsyncThunk("hasBids", async (auctionAddress: string) => {
  const { address: walletAddress } = await getKeplr();
  const auctionContract = new AuctionContract(walletAddress);
  const vk = (await ViewinKeyManager.getKey(AUCTION_FACTORY_ADDRESS)) as string;
  const hasBids = await auctionContract.hasBids(auctionAddress, vk);
  return hasBids;
});

export const getPlacedBid = createAsyncThunk<PlacedBidModel, any>(
  "getPlacedBid",
  async (auctionAddress: string) => {
    const { address: walletAddress } = await getKeplr();
    const auctionContract = new AuctionContract(walletAddress);
    const vk = (await ViewinKeyManager.getKey(AUCTION_FACTORY_ADDRESS)) as string;
    const bid = await auctionContract.getPlacedBid(auctionAddress, vk);
    const bidFormatted = new PlacedBidModel(bid);
    return bidFormatted;
  }
);

export const placeBid = createAsyncThunk<string, PlaceBidInputValues>(
  "placeBid",
  async ({ auctionAddress, bidContractAddress, amount }: PlaceBidInputValues) => {
    const { address: walletAddress } = await getKeplr();
    const auctionContract = new AuctionContract(walletAddress);
    const bid = coinConvert(amount, 6, "machine");
    const placedBid = await auctionContract.placeBid(auctionAddress, bidContractAddress, bid);
    return placedBid;
  }
);

export const retractBid = createAsyncThunk<void, string>(
  "retractBid",
  async (auctionAddress: string) => {
    const { address: walletAddress } = await getKeplr();
    const auctionContract = new AuctionContract(walletAddress);
    await auctionContract.retractBid(auctionAddress);
  }
);

export const finalizeAuction = createAsyncThunk<void, string>(
  "finalize",
  async (auctionAddress: string) => {
    const { address: walletAddress } = await getKeplr();
    const auctionContract = new AuctionContract(walletAddress);
    await auctionContract.finalize(auctionAddress);
  }
);

export const changeMinimumBid = createAsyncThunk<string, ChangeMinBidInputValues>(
  "changeMinBid",
  async ({ auctionAddress, amount }: ChangeMinBidInputValues) => {
    const { address: walletAddress } = await getKeplr();
    const auctionContract = new AuctionContract(walletAddress);
    const minBid = coinConvert(amount, 6, "machine");
    const bid = await auctionContract.changeMinimumBid(auctionAddress, minBid);
    return bid;
  }
);

export const verifyAuction = createAsyncThunk<void, VerifyInputValues>(
  "verifyAuction",
  async ({ auctionAddress, verification }: VerifyInputValues) => {
    const { address: walletAddress } = await getKeplr();
    const auctionContract = new AuctionContract(walletAddress);
    await auctionContract.verify(auctionAddress, verification);
  }
);

export const saveFailedAuction = createAsyncThunk<boolean, string>(
  "saveFailedAuction",
  async (auctionAddress: string) => {
    const auctionContract = new AuctionContract();
    const info = await auctionContract.getInfo(auctionAddress);
    if ((info as any).verification_status === "Failed") {
      const data = { data: { title: auctionAddress } };
      await API.post("auctions", data);
      return true;
    }
    return false;
  }
);
