import * as React from "react";
import { Stack, Toolbar, Box, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import useTheme from "@mui/material/styles/useTheme";
import NavLink from "components/NavLink";
import WalletButton from "components/WalletButton";
import blackLogo from "assets/logos/text-logo-black.png";
import whiteLogo from "assets/logos/text-logo-white.png";
import { ROUTES } from "app/utils/constants/routes";
import AddBoxIcon from "@mui/icons-material/AddBox";
import GridViewIcon from "@mui/icons-material/GridView";
import MapIcon from "@mui/icons-material/Map";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import { useAppSelector } from "features/store";
import useSettings from "app/hooks/useSettings";
import GlobalSearch from "components/GlobalSearch";

export const DesktopHeader = () => {
  const {
    palette: { mode },
  } = useTheme();
  const { setNotificationsOpen } = useSettings();
  const { isAuthenticated } = useAppSelector((s) => s.auth);
  const { unreadNotificationCount } = useAppSelector((s) => s.user);

  return (
    <Toolbar
      sx={{
        height: "80px",
        display: { xs: "none", lg: "flex" },
      }}
    >
      <Link to="/">
        <img
          loading="eager"
          src={mode === "light" ? blackLogo : whiteLogo}
          width={150}
          alt="logo"
        />
      </Link>
      <Stack
        flex={1}
        direction="row"
        pl={{ lg: 4, xl: 10 }}
        alignItems="flex-end"
        spacing={{ lg: 2, xl: 4 }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <NavLink
            to={ROUTES.MARKETPLACE}
            label="Marketplace"
            icon={<GridViewIcon style={{ fontSize: "16px", color: "#ccc" }} />}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <NavLink
            to={ROUTES.CREATE_AUCTION}
            label="Create Auction"
            icon={<AddBoxIcon style={{ fontSize: "16px", color: "#ccc" }} />}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <NavLink
            to={ROUTES.ACTI_SWAP}
            label="ACTI Swap"
            icon={<SwapHorizontalCircleIcon style={{ fontSize: "16px", color: "#ccc" }} />}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <NavLink
            to={ROUTES.ACTI_STUDIO}
            label="ACTI Studio"
            icon={<ArchitectureIcon style={{ fontSize: "16px", color: "#ccc" }} />}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <NavLink
            to={ROUTES.ROADMAP}
            label="Roadmap"
            icon={<MapIcon style={{ fontSize: "16px", color: "#ccc" }} />}
          />
        </Box>
      </Stack>
      <Stack direction="row" alignItems="end" spacing={2}>
        <GlobalSearch
          sx={{
            width: "180px", // default width
            "@media (min-width:1380px)": {
              width: "260px",
            },
            "@media (min-width:1440px)": {
              width: "320px",
            },
          }}
        />

        {isAuthenticated && (
          <IconButton onClick={() => setNotificationsOpen(true)}>
            <Badge badgeContent={unreadNotificationCount} color="error">
              <NotificationsIcon fontSize="small" />
            </Badge>
          </IconButton>
        )}
        <WalletButton />
      </Stack>
    </Toolbar>
  );
};
