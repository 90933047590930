import * as React from "react";
import { Box } from "@mui/material";
import { CoverPhoto } from "./CoverPhoto";
import { ProfilePhoto } from "./ProfilePhoto";

export const ProfileHeader = React.memo(() => {
  const [newImages, setNewImages] = React.useState({
    profile_photo: { file: null, link: null },
    cover_photo: { file: null, link: null },
  });

  const props = {
    newImages,
    setNewImages,
  };

  return (
    <Box>
      <CoverPhoto {...props} />
      <ProfilePhoto {...props} />
    </Box>
  );
});
