/* eslint-disable */
import * as React from "react";
import { Drawer, Box, Button } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useTheme } from "@mui/material/styles";

const SlideDrawer = ({ children, ...props }: any) => {
  const { open, toggleDrawer, onClickPrev = undefined, onClickNext = undefined } = props;
  const theme = useTheme();
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer} /* className={classes.root} */
      sx={{
        "& .MuiBackdrop-root": {
          marginTop: "80px",
        },
        "& .MuiDrawer-paper": {
          //  borderRadius: '8px 0px 0px 8px',
          boxShadow: "none",
          width: { xsx: "100%", md: "75%" },
          boxSizing: "border-box",
          marginTop: "80px",
          border: "none",
          background: theme.palette.background.paper,
        },
      }}
    >
      <Box
        zIndex={1000}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={0.5}
        pl={2}
        pr={2}
      >
        <Button onClick={toggleDrawer} color="secondary" startIcon={<ArrowLeftIcon />}>
          Back
        </Button>

        {onClickPrev && onClickNext && (
          <Box display="flex" gap={2}>
            <Button onClick={onClickPrev} color="secondary" startIcon={<ArrowLeftIcon />}>
              Prev
            </Button>
            <Button onClick={onClickNext} color="secondary" endIcon={<ArrowRightIcon />}>
              Next
            </Button>
          </Box>
        )}
      </Box>
      <Box p={{ xs: 2, md: 8 }} sx={{ pt: "8px!important" }} minHeight="80%" role="presentation">
        {children}
      </Box>
    </Drawer>
  );
};
export default SlideDrawer;
