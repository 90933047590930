import { coinConvert } from "secretjs-service/dist/esm/utils";

export class PlacedBidModel {
  status: string;

  message: string;

  amountBid: string;

  constructor(props: any) {
    this.status = props.status;
    this.message = props.message;
    this.amountBid = coinConvert(props.amount_bid, 6, "human");
  }
}
