import * as React from "react";
import { Box, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { listMyAuctions, placeBid } from "features/auctions";
import { useAppSelector, useAppDispatch } from "features/store";
import { LOADING } from "app/utils/constants/others";
import { PlaceBidInputValues } from "app/types/auctions";

interface Props {
  auctionAddress: string;
  minimumBid: string;
  bidContractAddress: string;
  isOwner: boolean;
  id: string;
}

export const PlaceBid = (props: Props) => {
  const { auctionAddress, bidContractAddress, isOwner, id } = props;

  const state = useAppSelector((s) => s.auctions);
  const dispatch = useAppDispatch();

  const [bid, setBid] = React.useState(null);

  const handleBidChange = (event) => {
    event.preventDefault();
    setBid(event.target.value);
  };

  const handlePlaceBid = async (): Promise<void> => {
    if (!bid) return;

    const inputValues: PlaceBidInputValues = {
      auctionAddress,
      bidContractAddress,
      amount: bid,
    };
    await dispatch(placeBid(inputValues));
    dispatch(listMyAuctions());
  };

  if (isOwner) return null;

  return (
    <Box display="flex" gap={2}>
      <TextField size="small" variant="outlined" fullWidth onChange={handleBidChange} />

      <LoadingButton
        sx={{ px: 4 }}
        loading={state.loading.placeBid === LOADING.PENDING}
        onClick={handlePlaceBid}
        color="primary"
        variant="contained"
        size="medium"
      >
        Bid
      </LoadingButton>
    </Box>
  );
};
