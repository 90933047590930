import { Snip721MintMsg, Snip721BatchMintNftMsg } from "secretjs-service/dist/esm/types";
import { CreateNftFormValues } from "app/types/nfts";

export const getMintingMsg = (
  nfts: CreateNftFormValues[]
): Snip721MintMsg | Snip721BatchMintNftMsg => {
  if (!nfts) throw new Error("Missing Nft");

  if (nfts.length === 1) {
    const nft = nfts[0];
    const msg: Snip721MintMsg = {
      mint_nft: {
        token_id: nft?.tokenId,
        public_metadata: {
          extension: {
            description: nft.description,
            name: nft.name,
            attributes: [],
            protected_attributes: [],
            media: [
              {
                file_type: "image",
                authentication: {
                  key: "",
                  user: "",
                },
                url: String(nft.image),
              },
            ],
          },
        },
        private_metadata: {},
      },
    };

    return msg;
  }

  const msg: Snip721BatchMintNftMsg = {
    batch_mint_nft: {
      mints: nfts.map((nft): Snip721MintMsg["mint_nft"] => {
        return {
          token_id: nft?.tokenId,
          public_metadata: {
            extension: {
              description: nft.description,
              name: nft.name,
              attributes: [],
              protected_attributes: [],
              media: [
                {
                  file_type: "image",
                  authentication: {
                    key: "",
                    user: "",
                  },
                  url: String(nft.image),
                },
              ],
            },
          },
          private_metadata: {},
        };
      }),
    },
  };
  return msg;
};
