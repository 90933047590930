import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Modal } from "components/Modal";
import { useAppDispatch, useAppSelector } from "features/store";
import { login } from "features/auth";
import KeyIcon from "@mui/icons-material/Key";
import { LOADING } from "app/utils/constants/others";
import { Footer } from "./components/Footer";

interface Props {
  withFooter?: boolean;
  isProtected?: boolean;
}

export const FullScreenLayout = (props: Props) => {
  const { withFooter = true, isProtected = false } = props;
  const { isAuthenticated, loading } = useAppSelector((s) => s.auth);
  const dispatch = useAppDispatch();

  const {
    palette: { mode },
  } = useTheme();

  const handleAuthenticate = () => {
    dispatch(login);
  };

  return (
    <Box
      sx={{
        backgroundImage:
          mode === "dark"
            ? "linear-gradient(to left bottom, #0C1322, #161d2c, #111)"
            : "linear-gradient(to right top, #e4e4e4, #ebebeb, #fff)",
      }}
    >
      <Outlet />
      {withFooter && <Footer />}
      {isProtected && loading.login === LOADING.SUCCEEDED && (
        <Modal
          open={!isAuthenticated}
          title="Wallet authentication."
          okButtonLabel={loading.login === LOADING.PENDING ? "Authenticating..." : "Authenticate"}
          handleOk={handleAuthenticate}
        >
          <Box display="flex" justifyContent="center">
            {loading.login === LOADING.PENDING ? (
              <CircularProgress size={32} />
            ) : (
              <KeyIcon sx={{ fontSize: "72px" }} color="primary" />
            )}
          </Box>
          <Stack alignItems="center" spacing={2}>
            <Typography variant="body2">Please authenticate your wallet .</Typography>
          </Stack>
        </Modal>
      )}
    </Box>
  );
};
