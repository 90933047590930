import { createSlice } from "@reduxjs/toolkit";
import { LOADING } from "app/utils/constants/others";
import { toast } from "react-toastify";
import {
  createCollection,
  listCollections,
  importCollection,
  createViewingKey,
  getCollectionCodeHash,
  initCollections,
  getCollectionOwner,
  isAddressWhiteListed,
  incremetCreatedSwaps,
  incremetCreatedAuctions,
} from "./reducers";
import { initialState } from "./state";

const collectionSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(createCollection.pending, (state) => {
      state.loading.createCollection = LOADING.PENDING;
    });
    builder.addCase(createCollection.fulfilled, (state, action) => {
      state.loading.createCollection = LOADING.SUCCEEDED;
      state.collections = action.payload.userCollections;
    });
    builder.addCase(createCollection.rejected, (state, action) => {
      state.error = action.error;
      state.loading.createCollection = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(importCollection.pending, (state) => {
      state.loading.importCollection = LOADING.PENDING;
    });
    builder.addCase(importCollection.fulfilled, (state, action) => {
      state.loading.importCollection = LOADING.SUCCEEDED;
      state.collections = action.payload;
    });
    builder.addCase(importCollection.rejected, (state, action) => {
      state.error = action.error;
      state.loading.importCollection = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(listCollections.pending, (state) => {
      state.loading.listCollections = LOADING.PENDING;
    });
    builder.addCase(listCollections.fulfilled, (state, action) => {
      state.collections = action.payload;
      state.loading.listCollections = LOADING.SUCCEEDED;
    });
    builder.addCase(listCollections.rejected, (state, action) => {
      state.error = action.error;
      state.loading.listCollections = LOADING.FAILED;
    });

    builder.addCase(createViewingKey.pending, (state) => {
      state.loading.createViewingKey = LOADING.PENDING;
    });
    builder.addCase(createViewingKey.fulfilled, (state) => {
      state.loading.createViewingKey = LOADING.SUCCEEDED;
    });
    builder.addCase(createViewingKey.rejected, (state, action) => {
      state.error = action.error;
      state.loading.createViewingKey = LOADING.FAILED;
    });
  },
});

export {
  createCollection,
  listCollections,
  importCollection,
  createViewingKey,
  getCollectionCodeHash,
  initCollections,
  getCollectionOwner,
  isAddressWhiteListed,
  incremetCreatedSwaps,
  incremetCreatedAuctions,
};

export default collectionSlice.reducer;
