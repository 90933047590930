export const ROUTES = {
  // marketplace
  MARKETPLACE_LIVE: "/marketplace/live",
  MARKETPLACE: "/marketplace",
  MARKETPLACE_UPCOMING: "/marketplace/upcoming",
  MARKETPLACE_CLOSED: "/marketplace/closed",

  // dashboard
  DASHBOARD: "/dashboard",
  DASHBOARD_AUCTIONS: "/dashboard/auctions",
  DASHBOARD_BIDS: "/dashboard/bids",
  DASHBOARD_VERIFICATION: "/dashboard/verification",
  DASHBOARD_NOTIFICATION: "/dashboard/notification",

  // profile
  PROFILE: "/profile",
  PROFILE_ACCOUNT: "/profile/account",
  PROFILE_ASSETS: "/profile/assets",
  PROFILE_REWARDS: "/profile/rewards",

  // create auction
  CREATE_AUCTION: "/create-auction",

  // roadmap
  ROADMAP: "/roadmap",

  // mint
  MINT: "/mint",

  // acti-studio
  ACTI_STUDIO: "/acti-studio",
  ACTI_STUDIO_MINT_NFT: "/acti-studio/mint-nft",
  ACTI_STUDIO_CREATE_COLLECTION: "/acti-studio/create-collection",

  // swap
  SWAP: "/swap",

  // acti-swap
  ACTI_SWAP: "/acti-swap",
};
