import { CreateCollectionFormValues } from "app/types/collections";
import { SNIP721_CODE_ID, SNIP721_CODE_HASH } from "app/utils/constants/addresses";
import { InitMsg } from "secretjs-service/dist/esm/types/Snip721Types";
import { getEntropyString } from "secretjs-service/dist/esm/utils";

export const getCollectionInitMsg = (
  walletAddress: string,
  formData: CreateCollectionFormValues
): InitMsg => {
  const entropy = getEntropyString(32);

  const labelRef = new Date().getTime();

  const validRoyalties = formData.royalty_info.royalties.filter(
    (r) => r.recipient !== "" && r.rate > 0
  );

  const royaltyInfo =
    validRoyalties?.length > 0
      ? {
          ...formData.royalty_info,
          royalties: validRoyalties,
        }
      : null;

  return {
    sender: walletAddress,
    code_id: Number(SNIP721_CODE_ID),
    code_hash: String(SNIP721_CODE_HASH),
    label: `${formData.name}-${labelRef}`,
    init_msg: {
      name: formData.name,
      symbol: formData.symbol,
      entropy,
      ...(royaltyInfo ? royaltyInfo : {}),
      config: {
        public_token_supply: formData.config_token_supply_is_public,
        public_owner: formData.config_owner_is_public,
        enable_sealed_metadata: formData.config_sealed_metadata_is_enabled,
        unwrapped_metadata_is_private: formData.config_unwrapped_metadata_is_private,
        minter_may_update_metadata: formData.config_minter_may_update_metadata,
        owner_may_update_metadata: formData.config_owner_may_update_metadata,
        enable_burn: formData.config_burn_is_enabled,
      },
    },
  };
};
