import { createSlice } from "@reduxjs/toolkit";
import { LOADING } from "app/utils/constants/others";
import { toast } from "react-toastify";
import {
  approveNftAccess,
  revokeNftAccess,
  listNfts,
  mint,
  setGlobalApproval,
  makeNftSwappable,
} from "./reducers";
import { initialState } from "./state";

const nftSlice = createSlice({
  name: "nft",
  initialState,
  reducers: {
    clearNftsState: (state) => {
      state.nfts = [];
      state.loading.listNfts = LOADING.IDLE;
    },
    selectNFT: (state, action) => {
      state.selectedNft = action.payload;
    },

    resetImportedCollection: (state) => {
      state.nfts = initialState.nfts;
    },

    changeDisclaimer: (state, action) => {
      state.isDisclaimerAccepted = action.payload;
    },

    setNftAccessApproval: (state, action) => {
      state.isSelectedNftAccessApproved = action.payload;
    },
    removeNftFromCollection: (state, action) => {
      state.nfts = state.nfts.filter((nft: any) => nft.id !== action.payload);
    },
    setNftOwnership: (state, action) => {
      const updatedNfts = state.nfts.map((nft: any) => {
        if (action.payload.nftId === nft.id) {
          return { ...nft, ownerIsPublic: action.payload.ownerIsPublic };
        }
        return nft;
      });
      state.nfts = updatedNfts;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(listNfts.pending, (state) => {
      state.loading.listNfts = LOADING.PENDING;
    });
    builder.addCase(listNfts.fulfilled, (state, action) => {
      state.nfts = action.payload.nfts;
      state.collectionAddress = action.payload.collection_address;
      state.loading.listNfts = LOADING.SUCCEEDED;
    });
    builder.addCase(listNfts.rejected, (state, action) => {
      state.loading.listNfts = LOADING.FAILED;
      state.error = action.error;
    });

    builder.addCase(mint.pending, (state) => {
      state.loading.mint = LOADING.PENDING;
    });
    builder.addCase(mint.fulfilled, (state, action) => {
      state.nfts = action.payload.nfts;
      state.loading.mint = LOADING.SUCCEEDED;
    });
    builder.addCase(mint.rejected, (state, action) => {
      state.loading.mint = LOADING.FAILED;
      state.error = action.error;
    });

    builder.addCase(approveNftAccess.pending, (state) => {
      state.loading.approveNftAccess = LOADING.PENDING;
    });
    builder.addCase(approveNftAccess.fulfilled, (state) => {
      state.loading.approveNftAccess = LOADING.SUCCEEDED;
      state.isSelectedNftAccessApproved = true;
      toast.success("Your NFT is transferable by ActiList.");
    });
    builder.addCase(approveNftAccess.rejected, (state, action) => {
      state.loading.approveNftAccess = LOADING.FAILED;
      state.error = action.error;
      toast.error(action.error.message);
    });

    builder.addCase(revokeNftAccess.pending, (state) => {
      state.loading.revokeNftAccess = LOADING.PENDING;
    });
    builder.addCase(revokeNftAccess.fulfilled, (state) => {
      state.loading.revokeNftAccess = LOADING.SUCCEEDED;
      state.isSelectedNftAccessApproved = false;
      toast.success("Your NFT is no longer transferable by ActiList");
    });
    builder.addCase(revokeNftAccess.rejected, (state, action) => {
      state.loading.revokeNftAccess = LOADING.FAILED;
      state.error = action.error;
      toast.error(action.error.message);
    });
  },
});

export { approveNftAccess, revokeNftAccess, listNfts, mint, setGlobalApproval, makeNftSwappable };

export const {
  selectNFT,
  resetImportedCollection,
  changeDisclaimer,
  setNftAccessApproval,
  removeNftFromCollection,
  setNftOwnership,
  clearNftsState,
} = nftSlice.actions;

export default nftSlice.reducer;
