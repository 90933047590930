import * as React from "react";
import { Typography, Stack } from "@mui/material";
import { LiveScrtPrice } from "components/LiveScrtPrice";
import scrtIcon from "assets/icons/scrt.png";
import timerIcon from "assets/icons/timer.png";
import { TextSmallSecondary, TextSmallPrimary } from "components/Typography";
import { secondsToTimeFormat } from "app/utils/helpers/time";

interface Props {
  endsAt?: string | undefined;
  startsAt?: string | undefined;
  minimumBid?: string | undefined;
  winningBid?: string | undefined;
}

export const AuctionInfo = (props: Props) => {
  const {
    endsAt = undefined,
    startsAt = undefined,
    minimumBid = undefined,
    winningBid = undefined,
  } = props;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="start" my={2}>
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <img src={scrtIcon} width={25} height={25} alt="scrt" />
          <Stack>
            <TextSmallSecondary>Minimum bid</TextSmallSecondary>
            <Typography variant="body2">
              {minimumBid}
              <span style={{ fontSize: "13px" }}> sSCRT</span>
              <LiveScrtPrice amount={minimumBid} />
            </Typography>
          </Stack>
        </Stack>

        {winningBid ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <img src={scrtIcon} width={25} height={25} alt="scrt" />
            <Stack>
              <TextSmallSecondary>Winning bid</TextSmallSecondary>
              <TextSmallPrimary>{winningBid}</TextSmallPrimary>
            </Stack>
          </Stack>
        ) : null}
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1}>
        <img src={timerIcon} width={25} height={25} alt="scrt" />
        <Stack>
          <TextSmallSecondary>{endsAt ? "End time:" : "Start time:"}</TextSmallSecondary>
          <TextSmallPrimary>
            {secondsToTimeFormat(endsAt) ?? secondsToTimeFormat(startsAt)}
          </TextSmallPrimary>
        </Stack>
      </Stack>
    </Stack>
  );
};
