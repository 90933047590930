import * as React from "react";
import { Box, Typography, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { changeMinimumBid } from "features/auctions";
import { indexAuction } from "services/auctions/auctionService";
import { useAppSelector, useAppDispatch } from "features/store";
import { LOADING } from "app/utils/constants/others";
import { ChangeMinBidInputValues } from "app/types/auctions";

interface Props {
  auctionAddress: string;
  isOwner: boolean;
  id: string;
}

export const ChangeMinimumBid = (props: Props) => {
  const { auctionAddress, isOwner } = props;

  const state = useAppSelector((s) => s.auctions);
  const dispatch = useAppDispatch();
  const [minimumBid, setMinimumBid] = React.useState<string>("");

  const handleChangeMinimumBid = (event) => {
    event.preventDefault();
    setMinimumBid(event.target.value);
  };

  const submitChangeMinimumBid = async () => {
    if (!minimumBid) return;
    const inputValues: ChangeMinBidInputValues = {
      auctionAddress: auctionAddress,
      amount: minimumBid,
    };
    await dispatch(changeMinimumBid(inputValues));
    await indexAuction(auctionAddress);
  };

  if (!isOwner) return null;

  return (
    <>
      <Typography variant="body2" color="textSecondary">
        Change Minimum Bid
      </Typography>

      <Box display="flex" gap={2}>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          value={minimumBid}
          onChange={handleChangeMinimumBid}
        />

        <LoadingButton
          sx={{ px: 4 }}
          loading={state.loading.changeMinimumBid === LOADING.PENDING}
          onClick={submitChangeMinimumBid}
          color="secondary"
          variant="outlined"
        >
          Change
        </LoadingButton>
      </Box>
    </>
  );
};
