import { API } from "app/libs/api";
import { CreateNftFormValues, NftDocument } from "app/types/nfts";
import { Snip721 } from "secretjs-service/dist/esm/contracts";
import { NftDossier } from "secretjs-service/dist/esm/types/Snip721Types";
import { ViewinKeyManager } from "app/utils/helpers/viewingKeyManager";
import { uploadToIpfs } from "app/libs/ipfs";
import { getMintingMsg } from "./helpers";

export const mint = async (
  walletAddress: string,
  collectionAddress: string,
  nfts: CreateNftFormValues[]
): Promise<string[]> => {
  const promises = nfts.map((nft: any) => uploadToIpfs(nft.image));
  const urls = await Promise.all(promises);

  const nftList: CreateNftFormValues[] = nfts.map((nft: any, i: number) => ({
    ...nft,
    image: urls[i],
  }));

  const snip721 = new Snip721(walletAddress);
  const msg = getMintingMsg(nftList);
  const res = await snip721.mint(collectionAddress, msg);

  // Ensure arrayLog exists and has the expected structure
  if (!res.arrayLog) throw new Error(res?.rawLog);

  let ids: string | string[] = (res as any).arrayLog.find(
    (log: { key: string; value: string | string[] }) => log.key === "minted"
  ).value;

  try {
    ids = typeof ids === "string" ? JSON.parse(ids) : ids;
  } catch (error) {
    throw new Error("Failed to parse minted IDs from response.");
  }

  // Ensure ids is an array of strings
  return Array.isArray(ids) ? (ids as string[]) : [String(ids)];
};

export const indexNft = async (collectionAddress: string, token: string) => {
  const res = await API.post(`/index/nfts/${collectionAddress}/${token}`);
  return res;
};

export const getNftDossier = async (
  walletAddress: string,
  contractAddress: string,
  tokenId: string,
  isPublic?: boolean
): Promise<NftDossier> => {
  const snip721 = new Snip721(walletAddress);
  const vk = !isPublic ? await ViewinKeyManager.getKey(contractAddress) : undefined;
  const nftDossier = await snip721.getNftDossier(contractAddress, tokenId, vk);
  return nftDossier;
};
