import { LOADING } from "app/utils/constants/others";
import { Offer } from "app/types/p2p";

type Loading = {
  grantAccess: string;
  revokeAccess: string;
  createOffer: string;
  getOffersBySender: string;
  getOffersByPeer: string;
  getOfferById: string;
  getNftDossier: string;
  acceptOffer: string;
  rejectOffer: string;
  removeOffer: string;
  getOffersNfts: string;
};

interface OfferNFTs {
  offerId: string;
  wanted: Array<any>;
  offered: Array<any>;
}

interface InitialState {
  offersSent: Offer[];
  offerSentNfts: OfferNFTs[];
  offersReceived: Offer[];
  offerReceivedNfts: OfferNFTs[];
  loading: Loading;
  error: any;
}
export const initialState: InitialState = {
  offersSent: [],
  offerSentNfts: [],
  offersReceived: [],
  offerReceivedNfts: [],
  loading: {
    grantAccess: LOADING.IDLE,
    revokeAccess: LOADING.IDLE,
    createOffer: LOADING.IDLE,
    getOffersBySender: LOADING.IDLE,
    getOffersByPeer: LOADING.IDLE,
    getOfferById: LOADING.IDLE,
    getNftDossier: LOADING.IDLE,
    acceptOffer: LOADING.IDLE,
    rejectOffer: LOADING.IDLE,
    removeOffer: LOADING.IDLE,
    getOffersNfts: LOADING.IDLE,
  },
  error: null,
};
