import { createAsyncThunk } from "@reduxjs/toolkit";
import { getKeplr } from "app/utils/keplr";
import { API } from "app/libs/api";
import { SecretJS } from "secretjs-service/dist/esm/SecretJS";
import { Snip721 } from "secretjs-service/dist/esm/contracts";
import { addressValidator } from "app/utils/helpers";
import { ViewinKeyManager } from "app/utils/helpers/viewingKeyManager";
import {
  prepareAndSaveCollection,
  saveCollection,
  getGlobalCollection,
} from "services/collections/collectionService";
import { CreateCollectionValues } from "app/types/collections";
import { Collection } from "app/types/collections";

export const createCollection = createAsyncThunk<
  { userCollections: Collection[]; collectionAddress: string },
  CreateCollectionValues
>("create-collection", async (createCollectionValues) => {
  try {
    const { address: walletAddress } = await getKeplr();
    const { userCollections, collectionAddress } = await prepareAndSaveCollection(
      walletAddress,
      createCollectionValues
    );
    return { userCollections, collectionAddress };
  } catch (error) {
    throw new Error("Failed to create collection: " + error);
  }
});

export const importCollection = createAsyncThunk<
  Array<Collection>,
  { collectionAddress: string; walletAddress: string }
>("import-collection", async ({ collectionAddress, walletAddress }) => {
  let collections: Collection[] = [];
  try {
    const newCollection = await getGlobalCollection(collectionAddress);

    if (newCollection) {
      collections = await saveCollection(walletAddress, newCollection);
    }
    return collections;
  } catch (error) {
    console.error();
    //TODO: NOtify user with toast message that automatic collection import failed.
    // collection has to be manually imported, or collection has to be minted via ActiList app
    // if ((error as any).response.status === 404) {
    //   const collectionData = await getGlobalCollection(collectionAddress);

    //   await API.post("/global-collections", { data: collectionData });

    //   const { data } = await saveCollection(walletAddress, collectionData);
    //   collections = data.collections;
    // }
  }

  return collections;
});

export const listCollections = createAsyncThunk(
  "list-user-collections",
  async (address: string) => {
    const { data } = await API.get(`/collections/${address}`);
    return data.collections;
  }
);

export const createViewingKey = createAsyncThunk<string, string>(
  "createViewingKey",
  async (collectionAddress: string) => {
    if (!addressValidator(collectionAddress)) {
      throw new Error("invalid address given!");
    }

    const { address: walletAddress } = await getKeplr();

    const existingKey = await ViewinKeyManager.getKey(collectionAddress);
    if (existingKey) {
      return existingKey;
    }

    const snip721 = new Snip721(walletAddress);
    const key = await snip721.createViewingKey(collectionAddress);
    ViewinKeyManager.add(walletAddress, collectionAddress, key);

    return key;
  }
);

export const getCollectionCodeHash = createAsyncThunk<string, string>(
  "get-code-hash",
  async (collectionAddress) => {
    const secretjs = new SecretJS();
    const codeHash = await secretjs.getCodeHash(collectionAddress);
    return codeHash;
  }
);

export const initCollections = createAsyncThunk("init-assets", async () => {
  const { address } = await getKeplr();
  try {
    await API.get(`/collections/${address}`);
  } catch (error) {
    if ((error as any)?.response.status === 404) {
      const data = {
        data: {
          owner: address,
          collections: [],
        },
      };

      API.post("/collections", data);
    } else {
      throw error;
    }
  }
});

export const getCollectionOwner = createAsyncThunk<string, string>(
  "getCollectionOwner",
  async (collectionAddress: string) => {
    const secretjs = new SecretJS();
    const { creator } = await secretjs.queryContractInfo(collectionAddress);
    return creator;
  }
);

export const isAddressWhiteListed = createAsyncThunk<any, any>(
  "isAddressWhiteListed",
  async ({ walletAddress, contractAddress, codeHash }) => {
    const { data } = await API.post("/whitelisting-status/", {
      walletAddress,
      contractAddress,
      codeHash,
    });
    return data;
  }
);

export const incremetCreatedAuctions = createAsyncThunk<void, string>(
  "incremetCreatedAuctions",
  async (address) => {
    const { data } = await API.get(`/global-collections/${address}`);
    if (data) {
      const updatedValue = data?.created_auctions ? data.created_auctions + 1 : 1;
      const updatedData = { ...data, created_auctions: updatedValue };
      await API.put(`/global-collections/${data.id}`, { data: { ...updatedData } });
    }
  }
);

export const incremetCreatedSwaps = createAsyncThunk<void, string>(
  "incremetCreatedSwaps",
  async (address) => {
    const { data } = await API.get(`/global-collections/${address}`);
    if (data) {
      const updatedValue = data?.created_swaps ? data.created_swaps + 1 : 1;
      const updatedData = { ...data, created_swaps: updatedValue };
      await API.put(`/global-collections/${data.id}`, { data: { ...updatedData } });
    }
  }
);
