import React, { useState } from "react";
import WalletIcon from "@mui/icons-material/Wallet";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Divider, Stack, Box, MenuItem, Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserAvatar from "components/UserAvatar";
import { ROUTES } from "app/utils/constants/routes";
import { useAppSelector, useAppDispatch } from "features/store";
import { connectWallet } from "features/auth";
import { LOADING } from "app/utils/constants/others";
import { TextSmallPrimary } from "components/Typography";
import { ThemeSwitcher } from "components/ThemeSwitcher";
import LoadingButton from "@mui/lab/LoadingButton";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import NotificationsIcon from "@mui/icons-material/Notifications";
import useSettings from "app/hooks/useSettings";
import { AuthButton } from "../AuthButton";
import TokensList from "./TokensList";
import { AccountMenu } from "./AccountMenu";

const style = {
  width: "100%",
  direction: "row",
  gap: 2,
  alignItems: "center",
};

const WalletButton = React.memo(() => {
  const [open, setOpen] = useState(false);

  const { walletAddress, loading, isAuthenticated } = useAppSelector((state) => state.auth);
  const { profileMedia } = useAppSelector((state) => state.user);
  const { setNotificationsOpen } = useSettings();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const connect = () => {
    dispatch(connectWallet());
  };

  if (!walletAddress) {
    return (
      <LoadingButton
        variant="contained"
        size="large"
        color="primary"
        startIcon={<WalletIcon />}
        onClick={connect}
        loading={loading.currentAuthenticated === LOADING.PENDING}
      >
        Connect
      </LoadingButton>
    );
  }
  return (
    <Hidden lgDown>
      <AccountMenu
        // endIcon={<ArrowDropDownIcon />}
        open={open}
        setOpen={setOpen}
        icon={<UserAvatar profile_photo={profileMedia.profile_photo} size="small" />}
      >
        <Box px={2}>
          <TokensList />
        </Box>
        <MenuItem onClick={() => navigate(ROUTES.SWAP)}>
          <Stack direction="row" sx={style} onClick={() => setOpen(false)}>
            <SwapHorizIcon fontSize="small" />
            <TextSmallPrimary>Swap SCRT</TextSmallPrimary>
          </Stack>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate(ROUTES.PROFILE_ACCOUNT)}>
          <Stack direction="row" sx={style} onClick={() => setOpen(false)}>
            <AccountBoxIcon fontSize="small" />
            <TextSmallPrimary>Profile</TextSmallPrimary>
          </Stack>
        </MenuItem>
        <MenuItem onClick={() => navigate(ROUTES.DASHBOARD)}>
          <Stack direction="row" sx={style} onClick={() => setOpen(false)}>
            <DashboardIcon fontSize="small" />
            <TextSmallPrimary>Dashboard</TextSmallPrimary>
          </Stack>
        </MenuItem>
        {isAuthenticated ? (
          <MenuItem onClick={() => setNotificationsOpen(true)}>
            <Stack direction="row" sx={style} onClick={() => setOpen(false)}>
              <NotificationsIcon fontSize="small" />
              <TextSmallPrimary>Notifications</TextSmallPrimary>
            </Stack>
          </MenuItem>
        ) : null}
        <Divider />
        <Box p={2} pt={0} pb={1}>
          <ThemeSwitcher />
        </Box>
        {walletAddress && (
          <Box p={2} pt={1}>
            <AuthButton fullWidth />
          </Box>
        )}
      </AccountMenu>
    </Hidden>
  );
});

export default WalletButton;
