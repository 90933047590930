export const addressValidator = (address: string) => {
  const match = address.match(/^secret[a-z0-9]/);
  const { length } = address.split("");
  if (!!match && length > 40 && length < 50) {
    return true;
  }
  return false;
};

export const extractAddressFromUrl = (url: string) => {
  if (!url) throw new Error("url not found!");

  const [address] = url.split("/").splice(-1);
  const isValidAddress = addressValidator(address);

  if (!isValidAddress) throw new Error("Address is not valid!");
  return address;
};

export const validateInt = (v) => {
  let error = false;
  const value = Number(v);
  if (v === "" || typeof value !== "number" || value < 0) {
    error = true;
  }
  return error;
};

export const validateText = (v) => {
  let error = false;

  if (v === "" || v.split().lenght < 40) {
    error = true;
  }

  return error;
};

export const nonIntersect = (arr1, arr2) => {
  const res = arr1.filter((unv) => {
    // eslint-disable-next-line
    for (let i = 0; i < arr2.length; i++) {
      if (unv.label === arr2[i].label) {
        return false;
      }
    }
    return true;
  });

  return res;
};

export const getPercentage = (num, percentage) => {
  return (num * percentage) / 100;
};

export const decodeVkData = (data: Uint8Array) => {
  if (!data) throw new Error("No Data found !");

  const decodedData = new TextDecoder().decode(data);
  const start = decodedData.indexOf("{") + 1;
  const end = decodedData.lastIndexOf("}");
  const result = decodedData.substring(start, end);

  const {
    viewing_key: { key },
  } = JSON.parse(`{${result}}`);
  return key;
};

export const isTokenApproved = (tokenApprovals: any, inventoryApprovals: any, address: string) => {
  let isApproved = false;
  let isInventoryApproved = false;
  if (Array.isArray(tokenApprovals)) {
    const res = tokenApprovals?.find((a) => a.address === address);
    isApproved = !!res;
  }
  if (Array.isArray(inventoryApprovals)) {
    const res = inventoryApprovals.find((a) => a.address === address);
    isInventoryApproved = !!res;
  }
  return !!(isApproved || isInventoryApproved);
};
