import React from "react";
import styled from "@mui/material/styles/styled";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { ThemeSwitcher } from "components/ThemeSwitcher";
import blackLogo from "assets/logos/text-logo-black.png";
import whiteLogo from "assets/logos/text-logo-white.png";
import { TextSmallSecondary } from "components/Typography";
import discordLogo from "assets/social-media/discord.png";
import telegramLogo from "assets/social-media/telegram.png";
import githubLogo from "assets/social-media/github.png";
import twitterLogo from "assets/social-media/twitter.png";
import linktreeLogo from "assets/social-media/linktree.png";

const ExternalFooterLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  marginTop: "8px!important",
  color: theme.palette.text.secondary,
  fontSize: "14px",
  "&:hover": { color: theme.palette.secondary.main },
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
}));

const LocalFooterLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  marginTop: "8px!important",
  color: theme.palette.text.secondary,
  fontSize: "14px",
  "&:hover": { color: theme.palette.secondary.main },
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
}));

const FooterContainer = styled("footer")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderTop: "1px solid",
  borderColor: theme.palette.divider,
  padding: "48px 0px",
}));

export const Footer = () => {
  const {
    palette: { mode },
  } = useTheme();

  return (
    <FooterContainer>
      <Container maxWidth="xl">
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ xs: "center", md: "start" }}
          justifyContent="space-between"
          spacing={3}
          mb={2}
        >
          <Stack
            spacing={3}
            sx={{
              maxWidth: "280px",
              alignItems: { xs: "center", md: "start" },
            }}
          >
            <Link to="/">
              <img
                loading="eager"
                src={mode === "light" ? blackLogo : whiteLogo}
                width={150}
                alt="logo"
              />
            </Link>
            <TextSmallSecondary style={{ textAlign: { xs: "center", md: "start" } }}>
              The first interactive NFT marketplace with confidential sales and customizable NFT
              access control.
            </TextSmallSecondary>
            <Stack direction="row" spacing={3} alignItems="center">
              <a href="https://twitter.com/ActiList_Dapp" target="_blank" rel="noreferrer">
                <img loading="eager" src={twitterLogo} width={25} alt="twitter" />
              </a>
              <a href="https://t.me/actilist" target="_blank" rel="noreferrer">
                <img loading="eager" src={telegramLogo} width={25} alt="telegram" />
              </a>
              <a href="https://discord.gg/uPxGvqJErz" target="_blank" rel="noreferrer">
                <img loading="eager" src={discordLogo} width={25} alt="discord" />
              </a>
              <a href="https://github.com/actilabs" target="_blank" rel="noreferrer">
                <img loading="eager" src={githubLogo} width={25} alt="github" />
              </a>
              <a href="https://linktr.ee/actilist?subscribe" target="_blank" rel="noreferrer">
                <img loading="eager" src={linktreeLogo} width={25} alt="github" />
              </a>
            </Stack>
          </Stack>
          <Stack spacing={2} sx={{ alignItems: { xs: "center", md: "start" } }}>
            <Typography variant="h6">Useful links</Typography>

            <LocalFooterLink to="/">Home</LocalFooterLink>
            <LocalFooterLink to="/marketplace">Marketplace</LocalFooterLink>
            <LocalFooterLink to="/create-auction">Create auction</LocalFooterLink>
            <LocalFooterLink to="/dashboard">Dashboard</LocalFooterLink>
            <LocalFooterLink to="/learning-portal">Learning portal</LocalFooterLink>
          </Stack>

          <Stack spacing={2} sx={{ alignItems: { xs: "center", md: "start" } }}>
            <Typography variant="h6">Featured articles</Typography>
            <ExternalFooterLink href="https://docs.actilist.io" target="_blank">
              Welcome
            </ExternalFooterLink>
            <ExternalFooterLink href="https://docs.actilist.io/docs/introduction" target="_blank">
              Introduction
            </ExternalFooterLink>
            <ExternalFooterLink
              href="https://docs.actilist.io/docs/getting-started"
              target="_blank"
            >
              Getting started
            </ExternalFooterLink>
            <ExternalFooterLink href="https://docs.actilist.io/docs/staking" target="_blank">
              Staking with us
            </ExternalFooterLink>
          </Stack>
        </Stack>
        <Container maxWidth="md">
          <Box display="flex">
            <ThemeSwitcher />
          </Box>
          <Divider />
          <Typography align="center" color="textSecondary" variant="body2" sx={{ pt: 2 }}>
            Copyrights © 2023 All rights reserved by ActiList
          </Typography>
        </Container>
      </Container>
    </FooterContainer>
  );
};
