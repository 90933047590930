import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { CircularProgress } from "@mui/material";
import { Modal } from "components/Modal";
import { login } from "features/auth";
import { useAppDispatch, useAppSelector } from "features/store";
import { useNavigate } from "react-router";

import { LOADING } from "app/utils/constants/others";

export const withAuth = (WrappedComponent: any) => {
  const AuthProvider = (props: any) => {
    const { isAuthenticated, loading } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [shouldShowModal, setShouldShowModal] = useState(false);
    const delay = 500; // Delay in milliseconds (500ms = 0.5 second)

    useEffect(() => {
      const timer = setTimeout(() => {
        setShouldShowModal(shouldModalPopUp());
      }, delay);

      return () => clearTimeout(timer);
    }, [isAuthenticated, loading]);

    const handleLogin = () => {
      dispatch(login());
    };

    const handleCancel = () => {
      if (loading.login === LOADING.PENDING) {
        dispatch(login()).abort();
      }
      navigate("/");
    };

    const shouldModalPopUp = () => {
      const isLoggedOut = !isAuthenticated;
      return isLoggedOut;
    };

    return (
      <>
        <WrappedComponent {...props} />
        <Modal
          open={shouldShowModal}
          title="Authentication required !"
          okButtonLabel="Authenticate"
          handleOk={handleLogin}
          cancelButtonLabel="Back"
          handleCancel={handleCancel}
          btnDisabled={loading.login === LOADING.PENDING}
        >
          <Box display="flex" justifyContent="center" py={3}>
            {loading.login === LOADING.PENDING ? (
              <CircularProgress />
            ) : loading.login !== LOADING.PENDING && !isAuthenticated ? (
              <VpnKeyIcon color="primary" sx={{ fontSize: "48px" }} />
            ) : null}
          </Box>
        </Modal>
      </>
    );
  };
  return AuthProvider;
};
