import React from "react";
import { Drawer, Paper } from "@mui/material";

const drawerWidth = 220;

interface PermanentDrawerProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  children: React.ReactElement;
}

interface TemporaryDrawerProps {
  children: React.ReactElement;
}

const TemporaryDrawerStyles = {
  display: { xs: "block", md: "none" },

  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: "80%",
  },
};

const PermanentDrawerStyles = {
  display: { xs: "none", md: "block", position: "sticky", top: "120px" },
  padding: "24px 16px 224px 0px",
  mt: 3,
  mr: 2,
  width: drawerWidth,

  "& .Mui-selected": {
    backgroundColor: "divider",
  },
  "& .MuiListItemIcon-root": { minWidth: "40px" },
  /* boxShadow: 'none',
    borderRight: '1px solid',
    borderColor: 'divider',
    borderRadius: 0 */
};

export const TemporaryDrawer = (props: PermanentDrawerProps) => {
  const { mobileOpen, handleDrawerToggle, children } = props;
  return (
    <Drawer
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={TemporaryDrawerStyles}
    >
      {children}
    </Drawer>
  );
};

export const PermanentDrawer = (props: TemporaryDrawerProps) => {
  const { children } = props;

  return (
    <Paper elevation={0} sx={PermanentDrawerStyles}>
      {children}
    </Paper>
  );
};
