import * as React from "react";
import { useEffect } from "react";
import { Box } from "@mui/material";

import { LOADING } from "app/utils/constants/others";
import { Loading } from "components/Loading";
import { useAppSelector, useAppDispatch } from "features/store";
import { getAuctionInfo, isAuctionOwner, isAuctionHasBids } from "features/auctions";
import { toast } from "react-toastify";
import { FinalizeAuction } from "../components/FinalizeAuction";
import { PlaceBid } from "../components/PlaceBid";
import { AuctionInfo } from "../components/AuctionInfo";
import { AuctionMetadata } from "../components/AuctionMetadata";
import { RetractBid } from "../components/RetractBid";
import { ChangeMinimumBid } from "../components/ChangeMinimumBid";
import { AuctionNFT } from "../components/AuctionNFT";
import { NftMetadata } from "../components/NftMetadata";

interface Props {
  auctionAddress: string;
  id?: string;
}

export const Details = (props: Props) => {
  const { auctionAddress, id } = props;

  const state = useAppSelector((s) => s.auctions);
  const { isAuthenticated, loading } = useAppSelector((s) => s.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAuctionInfo(auctionAddress));
    if (isAuthenticated) {
      dispatch(isAuctionOwner(auctionAddress));
      dispatch(isAuctionHasBids(auctionAddress));
    } else if (!isAuthenticated && loading.login !== LOADING.PENDING) {
      toast.info("Please authenticate to see your own auctions.");
    }
  }, [auctionAddress, dispatch, isAuthenticated]);

  if (state.loading.getAuctionInfo === LOADING.PENDING) {
    return <Loading />;
  }

  const { auction } = state;

  return (
    <Box display={{ xs: "block", md: "flex" }} pb={5} gap={4}>
      <Box flex={2}>
        <AuctionNFT nftUrl={auction?.image_url} />
        <NftMetadata auction={auction} />
      </Box>

      <Box flex={3}>
        <Box sx={{ mb: 4, p: 2, borderRadius: "12px", backgroundColor: "background.default" }}>
          <AuctionMetadata
            status={auction?.auctionStatus}
            name={auction?.name}
            endsAtTimestamp={auction?.ends_at_timestamp}
            description={auction?.description}
            hasBids={state?.hasBids}
            isOwner={state?.isOwner}
          />

          <AuctionInfo minimumBid={auction?.minimum_bid} endsAt={auction?.ends_at} />
        </Box>
        <Box sx={{ mb: 4, p: 2, borderRadius: "12px", backgroundColor: "background.default" }}>
          <PlaceBid
            auctionAddress={auctionAddress}
            bidContractAddress={auction?.bid_contract_address}
            minimumBid={auction?.minimum_bid}
            isOwner={state?.isOwner}
            id={id}
          />

          {id && (
            <ChangeMinimumBid auctionAddress={auctionAddress} isOwner={state?.isOwner} id={id} />
          )}

          <RetractBid auctionAddress={auctionAddress} />
        </Box>
        {id && (
          <FinalizeAuction
            auctionAddress={auctionAddress}
            isOwner={state?.isOwner}
            isEnded={auction?.auctionStatus?.isEnded}
            id={id}
          />
        )}
      </Box>
    </Box>
  );
};
