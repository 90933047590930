import { createAsyncThunk } from "@reduxjs/toolkit";
import { SNIP20_ADDRESS } from "app/utils/constants/addresses";
import { SecretJS } from "secretjs-service/dist/esm/SecretJS";
import { Snip20 } from "secretjs-service/dist/esm/contracts";
import { Permit } from "secretjs";
import { getKeplr } from "app/utils/keplr";
import { setPermitLS, getExistingPermitLS } from "./helpers";

export const setTokenVk = createAsyncThunk<any, any>("set-token-vk", async () => {
  const { address: walletAddress } = await getKeplr();
  const snip20 = new Snip20(walletAddress);
  await snip20.setTokenVk();
});

export const createWrappedSecretPermit = createAsyncThunk<any>("sign-permit", async () => {
  let permit = getExistingPermitLS(SNIP20_ADDRESS);

  if (!permit) {
    const { address: walletAddress } = await getKeplr();
    const secretJs = new SecretJS(walletAddress);

    permit = await secretJs.signPermit({
      chainId: "pulsar-3",
      permitName: "sSCRT permit",
      allowedContracts: [SNIP20_ADDRESS],
      permissions: ["balance"],
      keplr: true,
    });

    setPermitLS(SNIP20_ADDRESS, permit);
  }

  return permit;
});

export const getNativeBalance = createAsyncThunk<string>("get-n-balance", async () => {
  const { address: walletAddress } = await getKeplr();
  const secretJs = new SecretJS(walletAddress);
  const scrtBalance = await secretJs.getNativeBalance();

  return scrtBalance;
});

export const getWrappedSecretBalance = createAsyncThunk<string, { wrappedSecretPermit: Permit }>(
  "get-w-balance",
  async ({ wrappedSecretPermit }: { wrappedSecretPermit: Permit }) => {
    const { address: walletAddress } = await getKeplr();
    const snip20 = new Snip20(walletAddress);

    const sscrtBalance = await snip20.getWrappedSecretBalance(wrappedSecretPermit);
    return sscrtBalance;
  }
);

export const swapToken = createAsyncThunk<void, { amount: string; toWrapped: boolean }>(
  "swap",
  async ({ amount, toWrapped }: { amount: string; toWrapped: boolean }) => {
    const { address: walletAddress } = await getKeplr();
    const secretJs = new SecretJS(walletAddress);
    secretJs.swapToken(amount, toWrapped);
  }
);
