import React, { useEffect, useState } from "react";
import SlideDrawer from "components/SlideDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import { Details } from "modules/auctions-details/upcoming";
import { extractAddressFromUrl } from "app/utils/helpers";
import { ROUTES } from "app/utils/constants/routes";

import { useAppSelector } from "features/store";
// import { selectNextAuction, selectPrevAuction } from "./slice";

export const UpcomingAuctionDetailsSlider = () => {
  const [open, setOpen] = useState(false);
  const { selectedAuction } = useAppSelector((state) => state.auctions);
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const auctionAddress = selectedAuction?.address ?? extractAddressFromUrl(location.pathname);

  const goback = () => {
    setOpen(false);
    setTimeout(() => navigate(ROUTES.MARKETPLACE), 200);
  };

  const updateUrl = (address: string) => {
    const url = `${ROUTES.MARKETPLACE_UPCOMING}/${address}`;
    navigate(url, { replace: true });
  };

  const onClickNext = () => {
    // dispatch(selectNextAuction(upcomingAuctions));
    updateUrl(auctionAddress);
  };

  const onClickPrev = () => {
    // dispatch(selectPrevAuction(upcomingAuctions));
    updateUrl(auctionAddress);
  };

  useEffect(() => {
    setOpen(true);
  }, [auctionAddress]);

  return (
    <SlideDrawer
      open={open}
      toggleDrawer={goback}
      onClickNext={onClickNext}
      onClickPrev={onClickPrev}
    >
      <Details auctionAddress={auctionAddress} />
    </SlideDrawer>
  );
};
