import React, { useEffect, useRef } from "react";
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { TextSmallSecondary } from "components/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAppSelector } from "features/store";
import { bech32 } from "app/utils/constants";

interface Props {
  icon: any;
  open: boolean;
  setOpen: (arg: boolean) => void;
  children: any;
}
export const AccountMenu = (props: Props) => {
  const { children, icon, open, setOpen } = props;
  const anchorRef = useRef(null);
  const { profileInfo } = useAppSelector((state) => state.user);
  const { walletName, walletAddress } = useAppSelector((state) => state.auth);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box
        ref={anchorRef}
        onClick={handleToggle}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          cursor: "pointer",
        }}
      >
        <IconButton sx={{ padding: "2px" }}>{icon}</IconButton>
        <Box sx={{ "& .MuiTypography-root": { lineHeight: 1.2 } }}>
          <Typography variant="subtitle2" color="text.primary">
            {profileInfo?.username || walletName}
          </Typography>
          <TextSmallSecondary>{bech32(walletAddress, 14)}</TextSmallSecondary>
        </Box>
        <KeyboardArrowDownIcon
          color="primary"
          sx={{
            transition: "all 250ms linear",
            transform: open ? `rotate(180deg)` : "rotate(0deg)",
          }}
        />
      </Box>

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{
          width: "200px",
          "& .MuiButtonBase-root": { minHeight: 0 },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={5}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  sx={{
                    p: 0,
                    mt: 2,
                    "& .MuiMenuItem-root": {
                      minHeight: "48px",
                      minWidth: "160px",
                    },
                  }}
                >
                  <div>{children}</div>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
