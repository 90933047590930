import * as React from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import useSettings from "app/hooks/useSettings";
import useTheme from "@mui/material/styles/useTheme";
import { TextSmallPrimary } from "./Typography";

export const ThemeSwitcher = () => {
  const {
    palette: { mode },
  } = useTheme();
  const { switchThemeMode } = useSettings();

  const handleChange = () => {
    switchThemeMode();
  };

  return (
    <FormControlLabel
      control={
        <Switch
          sx={{ ml: 1 }}
          size="small"
          checked={mode === "dark"}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label={<TextSmallPrimary> Dark mode</TextSmallPrimary>}
    />
  );
};
