import { LOADING } from "app/utils/constants/others";

interface Loading {
  listNfts: string;
  mint: string;
  approveNftAccess: string;
  revokeNftAccess: string;
}
interface SelectedNFT {
  id: string;
  name: string;
  description: string;
  image: string;
  approvals: string[];
  collectionCreator: string;
  owner: string;
}

interface InitialState {
  collectionAddress: string;
  nfts: any;
  selectedNft: SelectedNFT | null;
  isSelectedNftAccessApproved: boolean;
  isDisclaimerAccepted: boolean;
  loading: Loading;
  error: unknown;
}

export const initialState: InitialState = {
  collectionAddress: "",
  nfts: [],
  selectedNft: null,
  isSelectedNftAccessApproved: false,
  isDisclaimerAccepted: false,
  loading: {
    listNfts: LOADING.IDLE,
    mint: LOADING.IDLE,
    approveNftAccess: LOADING.IDLE,
    revokeNftAccess: LOADING.IDLE,
  },
  error: null,
};
