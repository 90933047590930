import { createAsyncThunk } from "@reduxjs/toolkit";
import { addressValidator } from "app/utils/helpers";
import { getKeplr } from "app/utils/keplr";
import { ViewinKeyManager } from "app/utils/helpers/viewingKeyManager";
import { API } from "app/libs/api";
import { setAuthLS, getExistingAuthLS } from "./helpers";
import { FactoryContract } from "secretjs-service/dist/esm/contracts";
import { AUCTION_FACTORY_ADDRESS } from "secretjs-service/dist/esm/addresses";

export const login = createAsyncThunk("login", async () => {
  const { address: walletAddress } = await getKeplr();
  const appContract = new FactoryContract(walletAddress);

  const vk = await appContract.createAuthViewingKey();
  ViewinKeyManager.add(walletAddress, AUCTION_FACTORY_ADDRESS, vk);

  const {
    data: { jwt },
  } = await API.post("/auth/authenticate", { username: walletAddress, vk });
  setAuthLS(jwt);

  return { authVk: vk, jwt };
});

export const connectWallet = createAsyncThunk("connect", async () => {
  const { address, name } = await getKeplr();
  if (!addressValidator(address)) throw new Error("Attemp to connect unsupported wallet !");
  return {
    walletName: name,
    walletAddress: address,
  };
});

export const currentAuthenticatedUser = createAsyncThunk("current-auth-user", async () => {
  const { address: walletAddress } = await getKeplr();
  const jwt = getExistingAuthLS(walletAddress);
  if (!jwt) throw new Error("You are logged out !");
  const authVk = (await ViewinKeyManager.getKey(AUCTION_FACTORY_ADDRESS)) as string;
  return { authVk, jwt };
});
