export const gridSettings = (view = "grid") =>
  view === "grid"
    ? {
        xs: 12,
        sm: 6,
        md: 6,
        lg: 3,
        xl: 3,
      }
    : {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      };

export const bech32 = (str, abbrv) => {
  if (!str) return "";
  const half = abbrv / 2 || 8;
  return `${str.substring(0, half)}...${str.substring(str.length - half, str.length)}`;
};
