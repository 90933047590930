import { saveItemToLS, getItemFromLS } from "app/utils/helpers/localstorage";
import { Permit } from "secretjs";


interface PermitLS {
  token: string;
  permit: string;
}

export const getExistingPermitLS = (token: string) => {
  const currentPermits = getItemFromLS("acti-permits") ?? undefined
  const existing: PermitLS = currentPermits?.find((item: PermitLS) => item.token === token)

  const permitString = existing?.permit ?? undefined
  let permit = permitString ? JSON.parse(permitString) : undefined

  return permit
};

export const setPermitLS = async (token: string, permit: Permit) => {
  const permitString = JSON.stringify(permit);
  let currentPermits = getItemFromLS("acti-permits") ?? [];
  
  const permitIndex = currentPermits?.findIndex((perm: PermitLS) => perm.token === token);
  if (permitIndex < 0) {
    saveItemToLS("acti-permits", [...currentPermits, { token, permit: permitString }]);
  } else {
    currentPermits[permitIndex] = { ...currentPermits[permitIndex], permit: permitString }
    saveItemToLS("acti-permits", [...currentPermits]);
  }
  
};
