import { createAsyncThunk } from "@reduxjs/toolkit";
import { SecretJS } from "secretjs-service/dist/esm/SecretJS";
import { Snip721, P2PSwapContract } from "secretjs-service/dist/esm/contracts";
import { ViewinKeyManager } from "app/utils/helpers/viewingKeyManager";
import { NftDossierProps, OfferMsg } from "./types";
import { save } from "./helpers";

export const getCodeHash = createAsyncThunk("getCodeHash", async (contractAddress: string) => {
  const secretjs = new SecretJS();
  const codeHash = await secretjs.getCodeHash(contractAddress);
  return codeHash;
});

export const createOffer = createAsyncThunk<void, OfferMsg>(
  "createOffer",
  async ({ walletAddress, expiresAt, offeredNfts, peer, wantedNfts }) => {
    const p2pSwapContract = new P2PSwapContract(walletAddress);
    await p2pSwapContract.createOffer({ expiresAt, offeredNfts, peer, wantedNfts });
  }
);

export const getOffersBySender = createAsyncThunk(
  "getOffersBySender",
  async (walletAddress: string) => {
    const p2pSwapContract = new P2PSwapContract();
    const res = await p2pSwapContract.getOfferBySender(walletAddress);
    return (res as any).offers;
  }
);

export const getOffersByPeer = createAsyncThunk(
  "getOffersByPeer",
  async (walletAddress: string) => {
    const p2pSwapContract = new P2PSwapContract();
    const res = await p2pSwapContract.getOfferByPeer(walletAddress);
    return (res as any).offers;
  }
);

export const getOfferById = createAsyncThunk("getOfferById", async (id: string) => {
  const p2pSwapContract = new P2PSwapContract();
  const res = await p2pSwapContract.getOfferById(Number(id));
  return (res as any).offer;
});

export const getNftDossier = createAsyncThunk<any, NftDossierProps>(
  "getNftDossier",
  async ({ walletAddress, contractAddress, tokenId }) => {
    const snip721 = new Snip721(walletAddress);
    const vk = await ViewinKeyManager.getKey(contractAddress);
    const dossier = await snip721.getNftDossier(contractAddress, tokenId, vk);
    return (dossier as any)?.nft_dossier;
  }
);

export const acceptOffer = createAsyncThunk<any, any>(
  "acceptOffer",
  async ({ walletAddress, sender, offerId, recievedCollection, sentCollection }) => {
    const p2pSwapContract = new P2PSwapContract(walletAddress);
    await p2pSwapContract.acceptOffer(offerId);
    const collections = await save(walletAddress, recievedCollection);
    await save(sender, sentCollection);

    return collections;
  }
);

export const rejectOffer = createAsyncThunk<any, any>(
  "rejectOffer",
  async ({ walletAddress, offerId }) => {
    const p2pSwapContract = new P2PSwapContract(walletAddress);
    const res = await p2pSwapContract.rejectOffer(offerId);
    return res;
  }
);

export const removeOffer = createAsyncThunk<any, any>(
  "removeOffer",
  async ({ walletAddress, offerId }) => {
    const p2pSwapContract = new P2PSwapContract(walletAddress);
    const res = await p2pSwapContract.removeOffer(offerId);
    return res;
  }
);
