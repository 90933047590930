import * as React from "react";

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./features/store";
import App from "./App";
import secretjsEnv from "secretjs-service/dist/esm/config";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

const container = document.getElementById("root");
const root = createRoot(container as HTMLDivElement);

secretjsEnv.setEnv({
  SECRET_CHAIN_ID: import.meta.env.VITE_REACT_APP_SECRET_CHAIN_ID || "",
  SECRET_LCD_URL: import.meta.env.VITE_REACT_APP_SECRET_LCD_URL || "",
});

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
