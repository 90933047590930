import * as React from "react";
import { Box, Button } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CoverPlaceholder from "assets/default_cover.jpeg";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import LoadingButton from "@mui/lab/LoadingButton";

import { useAppSelector, useAppDispatch } from "features/store";
import { uploadMedia } from "features/users";
import { LOADING } from "app/utils/constants/others";
import { profileHeader, overlayContent } from "./styles";

const fileTypes = ["JPG", "PNG", "JPEG"];
interface Props {
  newImages: any;
  setNewImages: (p: any) => void;
}
export const CoverPhoto = (props: Props) => {
  const { newImages, setNewImages } = props;
  const [coverHovered, setCoverHovered] = React.useState(false);
  const { userId, profileMedia, loading } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const handleCoverPhotoChange = (img) => {
    setNewImages({
      ...newImages,
      cover_photo: { file: img, link: window.URL.createObjectURL(img) },
    });
  };

  const clearCoverPhoto = () => {
    setNewImages({
      ...newImages,
      cover_photo: { link: null, file: null },
    });
  };
  const saveImage = async () => {
    const img = newImages.cover_photo.file;
    const profileId = userId;
    const field = "cover_photo";
    dispatch(uploadMedia({ img, profileId, field }));
    clearCoverPhoto();
  };

  return (
    <Box
      onMouseEnter={() => setCoverHovered(true)}
      onMouseLeave={() => setCoverHovered(false)}
      sx={{
        ...profileHeader,
        // eslint-disable-next-line
        backgroundImage: newImages?.cover_photo.link
          ? `url("${newImages.cover_photo.link}")`
          : profileMedia?.cover_photo
          ? `url("${profileMedia?.cover_photo}")`
          : `url("${CoverPlaceholder}")`,
      }}
    >
      {/* eslint-disable-next-line */}
      {coverHovered && !newImages.cover_photo.link ? (
        <Box sx={overlayContent}>
          <Button endIcon={<EditIcon fontSize="small" />}>Drop or click to edit</Button>
        </Box>
      ) : newImages.cover_photo.link ? (
        <Box zIndex={30} sx={overlayContent}>
          <Button
            variant="outlined"
            endIcon={<DeleteIcon fontSize="small" />}
            size="small"
            color="error"
            onClick={clearCoverPhoto}
          >
            Remove
          </Button>
          <LoadingButton
            variant="contained"
            color="secondary"
            size="small"
            endIcon={<SaveAltIcon fontSize="small" />}
            onClick={saveImage}
            loading={loading.uploadMedia === LOADING.PENDING}
          >
            Save image
          </LoadingButton>
        </Box>
      ) : null}
      <FileUploader
        name="cover_photo"
        classes="imageuploader2"
        types={fileTypes}
        handleChange={handleCoverPhotoChange}
      />
    </Box>
  );
};
