import * as React from "react";
import Card from "@mui/material/Card";
import ImageLoader from "components/ImageLoader";
import PercentageProgress from "components/PercentageProgress";
import { ImageContainer } from "components/Styled/ImageContainer";

interface Props {
  nftUrl: string;
}

export const AuctionNFT = (props: Props) => {
  const { nftUrl } = props;

  return (
    <Card variant="outlined" sx={{ sx: 2 }}>
      <ImageContainer>
        <ImageLoader src={nftUrl}>
          {(progress) => <PercentageProgress progress={progress} />}
        </ImageLoader>
      </ImageContainer>
    </Card>
  );
};
