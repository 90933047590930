import React from "react";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "features/store";
import { LOADING } from "app/utils/constants/others";
import { SpinnerLoader } from "components/Spinner";
import EmptyScreen from "components/EmptyScreen";
import { getOffersBySender } from "features/swap";
import { Offer } from "../components/Offer";

export const OffersSent = () => {
  const { walletAddress } = useAppSelector((s) => s.auth);
  const { offersSent, loading } = useAppSelector((s) => s.swap);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (walletAddress && loading.getOffersBySender === LOADING.IDLE) {
      dispatch(getOffersBySender(walletAddress));
    }
  }, [walletAddress, dispatch]);

  if (loading.getOffersBySender === LOADING.SUCCEEDED && offersSent.length === 0) {
    return <EmptyScreen text="No Offers sent" />;
  }

  if (loading.getOffersBySender === LOADING.PENDING) {
    return <SpinnerLoader minHeight="35vh" />;
  }

  return (
    <Box>
      {offersSent?.map((offer) => (
        <Offer key={offer.id} offer={offer} />
      ))}
    </Box>
  );
};
