import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Outlet } from "react-router-dom";
import { withAuth } from "app/hoc";
import { ProfileHeader } from "./components/ProfileHeader";
import { ProfileSidebar } from "./components/ProfileSidebar";
import { PermanentDrawer } from "./components/Drawers";

const drawerWidth = 240;

export const ProfileLayout = withAuth(() => {
  return (
    <Container maxWidth="xl" sx={{ pt: 15 }}>
      <ProfileHeader />

      <Box sx={{ display: "flex" }}>
        <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
          <PermanentDrawer>
            <ProfileSidebar />
          </PermanentDrawer>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            p: 3,
            pr: 0,
            width: { md: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Container>
  );
});
