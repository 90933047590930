export const AUCTION_FACTORY_ADDRESS = "secret19qkdqq93gnuqfy6fw4hjgxyn2028yrwte00mry";
export const AUCTION_FACTORY_ADDRESS_CODE_HASH =
  "113f27c60f0ba576d240df6ed86bd4a9467e4ab6c8f57af639a93f5e63c30fc8";

export const SNIP20_ADDRESS = "secret1gvn6eap7xgsf9kydgmvpqwzkru2zj35ar2vncj";
export const SNIP20_ADDRESS_CODE_HASH =
  "c74bc4b0406507257ed033caa922272023ab013b0c74330efc16569528fa34fe";

export const SNIP721_CODE_ID = 1153;
export const SNIP721_CODE_HASH = "9b5e1ff71fa14aaf4f4811a5659e22f1c872cdb4e4d9cc590451e7996bdd9998";

export const P2P_ADDRESS = "secret1s0upmjh7zh8yyrt7gclu4wuevqgt4qjfm784uk";
