import React, { useEffect, useState, useCallback } from "react";
import SlideDrawer from "components/SlideDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import { Details } from "modules/auctions-details/closed";
import { extractAddressFromUrl } from "app/utils/helpers";
// import { ROUTES } from "app/utils/constants/routes";

import { useAppSelector, useAppDispatch } from "features/store";
// import { selectNextAuction, selectPrevAuction } from "./slice";

export const ClosedAuctionsDetailsSlider = () => {
  const [open, setOpen] = useState(false);
  const { selectedAuction, closedAuctions } = useAppSelector((state) => state.auctions);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const auctionAddress = selectedAuction?.address ?? extractAddressFromUrl(location.pathname);

  const goback = useCallback(() => {
    setOpen(false);
    setTimeout(() => navigate("/marketplace"), 200);
  }, [navigate]);

  const updateUrl = (address: string) => {
    const url = `/marketplace/active/${address}`;
    navigate(url, { replace: true });
  };

  const onClickNext = useCallback(() => {
    //  dispatch(selectNextAuction(closedAuctions));
    updateUrl(auctionAddress);
  }, [auctionAddress]);

  const onClickPrev = useCallback(() => {
    //   dispatch(selectPrevAuction(closedAuctions));
    updateUrl(auctionAddress);
  }, [auctionAddress]);

  useEffect(() => {
    setOpen(true);
  }, [auctionAddress]);

  return (
    <SlideDrawer
      open={open}
      toggleDrawer={goback}
      onClickNext={onClickNext}
      onClickPrev={onClickPrev}
    >
      <Details auctionAddress={auctionAddress} />
    </SlideDrawer>
  );
};
