import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { Hidden } from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CollectionsIcon from "@mui/icons-material/Collections";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const listItems = [
  {
    label: "Account",
    value: "account",
    icon: <AccountBoxIcon fontSize="small" />,
  },
  {
    label: "Assets",
    value: "assets",
    icon: <CollectionsIcon fontSize="small" />,
  },
  {
    label: "Missions",
    value: "rewards",
    icon: <EmojiEventsIcon fontSize="small" />,
  },
];

export const ProfileSidebar = React.memo(() => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box sx={{ overflow: "auto" }}>
      <List
        sx={{
          "& .Mui-selected": {
            borderLeft: "4px solid",
            borderColor: "primary.main",
          },
        }}
      >
        {listItems.map((item) => (
          <ListItem key={item.value} disablePadding sx={{ mb: 1 }}>
            <ListItemButton
              sx={{
                transition: "all 500ms linear",
                borderRadius: "0px 8px 8px 0px",
              }}
              selected={location.pathname.includes(item.value)}
              onClick={() => navigate(`/profile/${item.value}`)}
            >
              <ListItemIcon>{item?.icon}</ListItemIcon>
              <ListItemText secondary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
        <Hidden mdUp>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/")}>
              <ListItemText primary={<Typography align="left">Back Home</Typography>} />
            </ListItemButton>
          </ListItem>
        </Hidden>
      </List>
    </Box>
  );
});
