import { createSlice } from "@reduxjs/toolkit";
import { LOADING } from "app/utils/constants/others";
import { toast } from "react-toastify";
import { currentAuthenticatedUser, login, connectWallet } from "./reducers";
import { initialState } from "./state";
import { clearAuthLS, setAuthLS } from "./helpers";

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initialAuthLoad: (state, action) => {
      state.loading.currentAuthenticated = action.payload;
    },
    logout: () => {
      clearAuthLS();
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(connectWallet.pending, (state) => {
      state.loading.connect = LOADING.PENDING;
    });
    builder.addCase(connectWallet.fulfilled, (state, action) => {
      state.walletName = action.payload.walletName;
      state.walletAddress = action.payload.walletAddress;
      state.isWalletConnected = true;
      state.loading.connect = LOADING.SUCCEEDED;
    });
    builder.addCase(connectWallet.rejected, (state, action) => {
      state.error = action.error;
      state.loading.connect = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(login.pending, (state) => {
      state.loading.login = LOADING.PENDING;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      setAuthLS(action.payload.jwt);
      state.isWalletConnected = true;
      state.authVk = action.payload.authVk;
      state.jwt = action.payload.jwt;
      state.isAuthenticated = true;
      state.loading.login = LOADING.SUCCEEDED;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = action.error;
      state.loading.login = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(currentAuthenticatedUser.pending, (state) => {
      state.loading.currentAuthenticated = LOADING.PENDING;
    });
    builder.addCase(currentAuthenticatedUser.fulfilled, (state, action) => {
      state.authVk = action.payload.authVk;
      state.jwt = action.payload.jwt;
      state.isAuthenticated = true;
      state.loading.currentAuthenticated = LOADING.SUCCEEDED;
    });
    builder.addCase(currentAuthenticatedUser.rejected, (state, action) => {
      state.error = action.error;
      state.loading.currentAuthenticated = LOADING.FAILED;
    });
  },
});

export { currentAuthenticatedUser, login, connectWallet };
export const { initialAuthLoad, logout } = authSlice.actions;
export default authSlice.reducer;
