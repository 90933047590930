import * as React from "react";
import { Container } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useTheme } from "@mui/material/styles";
import { AppBar } from "../styled";
import { MobileHeader } from "./Mobile";
import { DesktopHeader } from "./Desktop";

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    style: {
      backgroundColor: "transparent",
      backdropFilter: !trigger ? "blur(0px)" : "blur(20px)",
      WebkitBackdropFilter: !trigger ? "blur(0px)" : "blur(20px)",
      borderBottom: !trigger ? "none" : "1px solid rgba(128,128,128,0.2)",
    },
  });
}

interface HeaderProps {
  handleDrawerToggle: () => void;
}

const Header = React.memo((props: HeaderProps) => {
  const { handleDrawerToggle } = props;
  const theme = useTheme();
  return (
    <ElevationScroll theme={theme} {...props}>
      <AppBar elevation={0} position="fixed">
        <Container
          maxWidth={false}
          sx={{
            "& .MuiToolbar-root": {
              paddingLeft: "0px!important",
              paddingRight: 0,
            },
          }}
        >
          <MobileHeader handleDrawerToggle={handleDrawerToggle} />
          <DesktopHeader />
        </Container>
      </AppBar>
    </ElevationScroll>
  );
});

export default Header;
