import * as React from "react";
import { LiveAuctionsDetailsSlider } from "modules/marketplace/LiveAuctionsDetailsSlider";
import { Helmet } from "react-helmet";

const LiveAuctionsDetails = () => (
  <>
    <Helmet>
      <title>Marketplace | Details</title>
    </Helmet>
    <LiveAuctionsDetailsSlider />
  </>
);

export default LiveAuctionsDetails;
