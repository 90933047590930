import { createSlice } from "@reduxjs/toolkit";
import { LOADING } from "app/utils/constants/others";
import { toast } from "react-toastify";
import { initialState } from "./state";
import {
  getCodeHash,
  createOffer,
  getOffersBySender,
  getOffersByPeer,
  getOfferById,
  getNftDossier,
  acceptOffer,
  rejectOffer,
  removeOffer,
} from "./reducers";

const actiSwapSlice = createSlice({
  name: "actiSwap",
  initialState,
  reducers: {
    updateSentOffers: (state, action) => {
      const id = action.payload;
      const newOffersSent = state.offersSent.filter((offer) => offer.id !== id);
      return { ...state, offersSent: newOffersSent };
    },
    updateReceivedOffers: (state, action) => {
      const id = action.payload;
      const newOffersReceived = state.offersReceived.filter((offer) => offer.id !== id);
      return { ...state, offersReceived: newOffersReceived };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createOffer.pending, (state) => {
      state.loading.createOffer = LOADING.PENDING;
    });
    builder.addCase(createOffer.fulfilled, (state) => {
      state.loading.createOffer = LOADING.SUCCEEDED;
      toast.success("Swap Offer Successfully placed .");
    });
    builder.addCase(createOffer.rejected, (state, action) => {
      state.error = action.error;
      state.loading.createOffer = LOADING.FAILED;
      toast.error(action.error.message);
    });
    builder.addCase(getOffersBySender.pending, (state) => {
      state.loading.getOffersBySender = LOADING.PENDING;
    });
    builder.addCase(getOffersBySender.fulfilled, (state, action) => {
      state.offersSent = action.payload;
      state.loading.getOffersBySender = LOADING.SUCCEEDED;
    });
    builder.addCase(getOffersBySender.rejected, (state, action) => {
      state.error = action.error;
      state.loading.getOffersBySender = LOADING.FAILED;
    });

    builder.addCase(getOffersByPeer.pending, (state) => {
      state.loading.getOffersByPeer = LOADING.PENDING;
    });
    builder.addCase(getOffersByPeer.fulfilled, (state, action) => {
      state.offersReceived = action.payload;
      state.loading.getOffersByPeer = LOADING.SUCCEEDED;
    });
    builder.addCase(getOffersByPeer.rejected, (state, action) => {
      state.error = action.error;
      state.loading.getOfferById = LOADING.FAILED;
    });
    builder.addCase(getOfferById.pending, (state) => {
      state.loading.getOfferById = LOADING.PENDING;
    });
    builder.addCase(getOfferById.fulfilled, (state, action) => {
      state.offersReceived = action.payload;
      state.loading.getOfferById = LOADING.SUCCEEDED;
    });
    builder.addCase(getOfferById.rejected, (state, action) => {
      state.error = action.error;
      state.loading.getOfferById = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(getNftDossier.pending, (state) => {
      state.loading.getNftDossier = LOADING.PENDING;
    });
    builder.addCase(getNftDossier.fulfilled, (state) => {
      state.loading.getNftDossier = LOADING.SUCCEEDED;
    });
    builder.addCase(getNftDossier.rejected, (state, action) => {
      state.error = action.error;
      state.loading.getNftDossier = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(acceptOffer.pending, (state) => {
      state.loading.acceptOffer = LOADING.PENDING;
    });
    builder.addCase(acceptOffer.fulfilled, (state) => {
      state.loading.acceptOffer = LOADING.SUCCEEDED;
    });
    builder.addCase(acceptOffer.rejected, (state, action) => {
      state.error = action.error;
      toast.error(action.error.message);
      state.loading.acceptOffer = LOADING.FAILED;
    });

    builder.addCase(rejectOffer.pending, (state) => {
      state.loading.rejectOffer = LOADING.PENDING;
    });
    builder.addCase(rejectOffer.fulfilled, (state) => {
      state.loading.rejectOffer = LOADING.SUCCEEDED;
    });
    builder.addCase(rejectOffer.rejected, (state, action) => {
      state.error = action.error;
      toast.error(action.error.message);
      state.loading.rejectOffer = LOADING.FAILED;
    });

    builder.addCase(removeOffer.pending, (state) => {
      state.loading.removeOffer = LOADING.PENDING;
    });
    builder.addCase(removeOffer.fulfilled, (state) => {
      state.loading.removeOffer = LOADING.SUCCEEDED;
    });
    builder.addCase(removeOffer.rejected, (state, action) => {
      state.error = action.error;
      toast.error(action.error.message);
      state.loading.removeOffer = LOADING.FAILED;
    });
  },
});

export {
  getCodeHash,
  createOffer,
  getOffersBySender,
  getOffersByPeer,
  getOfferById,
  getNftDossier,
  acceptOffer,
  rejectOffer,
  removeOffer,
};

export const { updateSentOffers, updateReceivedOffers } = actiSwapSlice.actions;
export default actiSwapSlice.reducer;
