import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ROUTES } from "app/utils/constants/routes";

export const Toolbar = () => {
  return (
    <Box display="flex" alignItems="center" gap={1} mb={2}>
      <Link to={ROUTES.ACTI_SWAP} style={{ textDecoration: "none" }}>
        <IconButton color="primary">
          <KeyboardBackspaceIcon />
        </IconButton>
      </Link>
      <Typography variant="h5" color="text.secondary">
        NFT Swap Offers
      </Typography>
    </Box>
  );
};
