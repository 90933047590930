import React from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

interface Props {
  label: string;
  to: string;
  icon?: any;
  small?: boolean;
}
const NavLink = React.memo((props: Props) => {
  const { label, to, icon, small = true } = props;
  return (
    <Typography
      sx={{
        transition: "color 0.15s ease-in-out",
        textDecoration: "none",
        "&:hover": {
          color: "secondary.main",
        },
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
      color="text.secondary"
      variant={small ? "body2" : "body1"}
      component={Link}
      to={to}
    >
      {icon && <span style={{ display: "flex", alignItems: "center" }}>{icon}</span>}
      {label}
    </Typography>
  );
});

export default NavLink;
