import { API } from "app/libs/api";

export const uploadToIpfs = async (image: File) => {
  let result = undefined;
  try {
    const formData = new FormData();
    formData.append("files", image);

    const response = await API.post("/ipfs/uploads", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    result = response.data.url;
  } catch (error) {
    console.error("Error uploading to IPFS:", error);
  }
  return result;
};
