import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { TextSmallSecondary } from "components/Typography";

type Props = {
  title: string;
  description: string;
};

export const Toolbar = ({ title, description }: Props) => {
  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        minHeight: "48px",
        display: "flex",
        alignItems: "center",
        gap: 2,
        px: 2,
      }}
    >
      <Typography variant="h6" fontSize="14px">
        {title}
      </Typography>{" "}
      | <TextSmallSecondary>{description} </TextSmallSecondary>
    </Box>
  );
};
