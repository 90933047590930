import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

import { useAppSelector, useAppDispatch } from "features/store";
import { login, logout } from "features/auth";
import { LOADING } from "app/utils/constants/others";

interface Props {
  fullWidth?: boolean;
}

export const AuthButton = ({ fullWidth }: Props) => {
  const { isAuthenticated, loading } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handlleAuthenticate = () => dispatch(login());
  const handleLogout = () => dispatch(logout());

  if (isAuthenticated) {
    return (
      <LoadingButton onClick={handleLogout} variant="outlined" fullWidth={fullWidth}>
        Logout
      </LoadingButton>
    );
  }
  return (
    <LoadingButton
      onClick={handlleAuthenticate}
      variant="outlined"
      loading={loading.login === LOADING.PENDING}
      fullWidth={fullWidth}
    >
      Authenticate
    </LoadingButton>
  );
};
