import * as React from "react";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";

import { withAuth } from "app/hoc";
import { Container } from "@mui/material";
import { DashboardSidebar } from "./components/DashboardSidebar";
import { PermanentDrawer } from "./components/Drawers";

const drawerWidth = 240;
const mainStyle = {
  flexGrow: 1,
  p: 3,
  width: { md: `calc(100% - ${drawerWidth}px)` },
};

export const DashboardLayout = withAuth(() => {
  return (
    <Container maxWidth="xl" sx={{ display: "flex", pt: 15 }}>
      <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
        <PermanentDrawer>
          <DashboardSidebar />
        </PermanentDrawer>
      </Box>
      <Box component="main" sx={mainStyle}>
        <Outlet />
      </Box>
    </Container>
  );
});
