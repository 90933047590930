import React from "react";
import { Box, Button, Card, Divider, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";

import { NFT } from "./NFT";

interface Props {
  offer: any;
  received?: boolean;
}

const offerBoxStyle = {
  flex: 1,
  p: 1,
  direction: "column",
  maxHeight: "260px",
  overflowY: "auto",
};

export const Offer = (props: Props) => {
  const { offer, received = false } = props;
  // eslint-disable-next-line
  const { offered_nfts, wanted_nfts, id } = offer;

  const navigate = useNavigate();

  const navigateToOfferDetails = () => {
    navigate(`/acti-swap/offers/${id}`, { state: { offer, received } });
  };
  return (
    <Box mb={2}>
      <Card
        variant="outlined"
        sx={{ display: "flex", cursor: "pointer", "& scrollbar": { display: "block!important" } }}
        onClick={navigateToOfferDetails}
      >
        <Box display="flex" flex={1}>
          <Stack spacing={1} sx={offerBoxStyle}>
            {wanted_nfts.map((nft) => (
              <NFT key={nft.token_id} nft={nft} />
            ))}
          </Stack>

          <Divider flexItem orientation="vertical" sx={{ mx: 2 }}>
            <SwapHorizontalCircleIcon fontSize="small" />
          </Divider>

          <Stack spacing={1} sx={offerBoxStyle}>
            {offered_nfts.map((nft) => (
              <NFT key={nft.token_id} nft={nft} />
            ))}
          </Stack>
        </Box>
      </Card>
      <Stack spacing={1} direction="row" justifyContent="end" p={1}>
        <Button onClick={navigateToOfferDetails}> Details</Button>
      </Stack>
    </Box>
  );
};
