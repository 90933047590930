import date from "date-and-time";

export const secondsToTimeFormat = (seconds) => {
  if (!seconds) return undefined;
  const sec = Number(seconds) * 1000;
  const d = new Date(sec);
  const formattedDate = date.format(d, "MM/DD/YYYY HH:mm:ss");

  return formattedDate;
};

export const secondsToDuration = (seconds: number) => {
  if (!seconds) return undefined;

  let result;

  const days = Math.floor(seconds / (24 * 3600));
  seconds -= days * 24 * 3600;

  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;

  const minutes =
    Math.floor(seconds / 60) > 10 ? Math.floor(seconds / 60) : `0${Math.floor(seconds / 60)}`;
  if (days > 0) {
    result = `${days} days ${hours}:${minutes}:00`;
  } else {
    result = ` ${hours}:${minutes}:00`;
  }

  return result;
};

export const calculateDurationFromTimestamps = (startTimestamp: number, endTimestamp: number) => {
  if (!startTimestamp || !endTimestamp || startTimestamp >= endTimestamp) {
    return undefined;
  }

  const durationInSeconds = endTimestamp - startTimestamp;
  return secondsToDuration(durationInSeconds);
};

export const getTimePassed = (start, end) => {
  let result;
  const now = new Date().getTime() / 1000;
  const duration = end - start;
  const passed = now - start;
  const percentage = (passed / duration) * 100;

  if (percentage >= 100) {
    result = 100;
  } else if (percentage < 0) {
    result = 0;
  } else {
    result = Math.round(percentage);
  }
  return result;
};
