import * as React from "react";
import {
  Divider,
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Dialog,
  Button,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line
  //@ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  title?: string;
  icon: any;
  content: any;
  handleCancel: () => void;
  cancelButtonLabel: string;
  handleOk: () => void;
  okButtonLabel: string;
  btnDisabled?: boolean;
}

const CustomDialog = (props: Props) => {
  const {
    open,
    title,
    icon,
    content,
    handleCancel,
    cancelButtonLabel,
    handleOk,
    okButtonLabel,
    btnDisabled,
  } = props;

  return (
    <Dialog
      open={open}
      // eslint-disable-next-line
      //@ts-ignore
      TransitionComponent={Transition}
      keepMounted
      sx={{
        zIndex: 10000,
        "& .MuiPaper-root": {
          minWidth: { xs: "250px", md: "500px" },
          backgroundImage: "unset",
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <Box pl={3} pr={3}>
        <Divider />
      </Box>
      <DialogContent>
        <Box
          sx={{
            minHeight: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
            justifyContent: "center",
          }}
        >
          {icon && <Box>{icon}</Box>}
          <span style={{ width: "100%" }}>{content}</span>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, pb: 3 }}>
        {cancelButtonLabel && (
          <Button size="medium" color="secondary" variant="outlined" onClick={handleCancel}>
            {cancelButtonLabel}
          </Button>
        )}
        {okButtonLabel && (
          <Button
            size="medium"
            disabled={btnDisabled}
            variant="contained"
            color="primary"
            onClick={handleOk}
          >
            {okButtonLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
