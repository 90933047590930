import React from "react";
import { Box, Tab, Tabs, Card } from "@mui/material";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Toolbar } from "../components/Toolbar";

export const OffersLists = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (location.pathname.includes("sent")) {
      setValue(1);
    }
  }, []);

  return (
    <>
      <Toolbar />
      <Card variant="outlined" sx={{ minHeight: "70vh" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", borderRadius: "8px 8px 0px 0px " }}>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{ "& button": { fontWeight: "700", textTransform: "none", fontSize: "14px" } }}
          >
            <Tab label="Received" onClick={() => navigate("/acti-swap/offers/lists/received")} />
            <Tab label="Sent" onClick={() => navigate("/acti-swap/offers/lists/sent")} />
          </Tabs>
        </Box>
        <Box p={2}>
          <Outlet />
        </Box>
      </Card>
    </>
  );
};
