import * as React from "react";
import { ClosedAuctionsDetailsSlider } from "modules/marketplace/ClosedAuctionsDetailsSlider";
import { Helmet } from "react-helmet";

const ClosedAuctionsDetails = () => (
  <>
    <Helmet>
      <title>Marketplace | Details</title>
    </Helmet>
    <ClosedAuctionsDetailsSlider />
  </>
);

export default ClosedAuctionsDetails;
