import { AuctionStatus } from "../types";
import { coinConvert } from "secretjs-service/dist/esm/utils";

export class AuctionModel {
  name: string;

  description: string;

  image_url: string;

  auction_address: string;

  collection_name: string;

  minimum_bid: string;

  winning_bid: string;

  starts_at: string;

  ends_at: string;

  verification_status: string;

  bid_contract_address: string;

  nft_description: string;

  ends_at_timestamp: number;

  starts_at_timestamp: number;

  isClosed: boolean;

  status: string;

  auctionStatus: AuctionStatus;

  constructor(props: any) {
    this.name = props.sell_token.token_info.extension.name;
    this.description = props.description;
    this.nft_description = props.sell_token.token_info.extension.description;
    this.image_url = props.sell_token.token_info.extension.media[0].url;
    this.starts_at = props.starts_at;
    this.ends_at = props.ends_at;
    this.collection_name = props.collection_name;
    this.minimum_bid = coinConvert(props.minimum_bid, 6, "human");
    this.winning_bid = props.winning_bid;
    this.auction_address = props.auction_address;
    this.verification_status = props.verification_status;
    this.bid_contract_address = props.bid_token.contract_address;
    this.ends_at_timestamp = Date.parse(this.ends_at) / 1000;
    this.starts_at_timestamp = Date.parse(this.starts_at) / 1000;
    this.status = props.status;
    this.auctionStatus = this.getAuctionStatus();
  }

  getAuctionStatus() {
    const d = new Date().getTime() / 1000;
    const now = Math.round(d);

    let auctionStatus: AuctionStatus = {
      isEnded: false,
      badges: [{ label: "accepting bids", color: "success" }],
    };

    if (this.status === "Closed") {
      auctionStatus = {
        isEnded: true,
        badges: [
          {
            label: this.winning_bid ? "sold" : "closed",
            color: "error",
          },
        ],
      };
      return auctionStatus;
    }
    if (now < this.starts_at_timestamp) {
      auctionStatus.badges = [
        {
          label: "Upcoming",
          color: "info",
        },
      ];
      return auctionStatus;
    }

    if (now >= this.ends_at_timestamp) {
      auctionStatus.isEnded = true;
      auctionStatus.badges.push({
        label: "ready to finalize",
        color: "error",
      });
      return auctionStatus;
    }
    return auctionStatus;
  }
}
