import { API } from "app/libs/api";

export const save = async (walletAddress: string, newCollection) => {
  const {
    data: { collections },
  } = await API.get(`/collections/${walletAddress}`);
  if (!collections) throw new Error("collections does not exist !");

  console.log("COLLECTIONS", collections);
  const exist = collections.find(
    (col) => col.collection_address === newCollection.collection_address
  );
  console.log("exist", exist);
  if (exist) return null;

  const data = {
    owner: walletAddress,
    collections: [...collections, newCollection],
  };

  const res = await API.put(`/collections/${walletAddress}`, data);
  console.log("REEEEs", res);
  return res.data.collections;
};
