import React, { Children } from "react";
import Typesense from "typesense";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { SxProps, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useAppDispatch, useAppSelector } from "features/store";
import { getNftDossier } from "services/nfts/nftService";
import SlideDrawer from "../SlideDrawer";

// Initialize Typesense clients for each index
const nftsClient = new Typesense.Client({
  nodes: [
    {
      host: import.meta.env.VITE_REACT_APP_SEARCH_HOST,
      port: parseInt(import.meta.env.VITE_REACT_APP_SEARCH_PORT, 10),
      protocol: import.meta.env.VITE_REACT_APP_SEARCH_PROTOCOL,
    },
  ],
  apiKey: "xyz",
});

const auctionsClient = new Typesense.Client({
  nodes: [
    {
      host: import.meta.env.VITE_REACT_APP_SEARCH_HOST,
      port: parseInt(import.meta.env.VITE_REACT_APP_SEARCH_PORT, 10),
      protocol: import.meta.env.VITE_REACT_APP_SEARCH_PROTOCOL,
    },
  ],
  apiKey: "xyz",
});

const collectionsClient = new Typesense.Client({
  nodes: [
    {
      host: import.meta.env.VITE_REACT_APP_SEARCH_HOST,
      port: parseInt(import.meta.env.VITE_REACT_APP_SEARCH_PORT, 10),
      protocol: import.meta.env.VITE_REACT_APP_SEARCH_PROTOCOL,
    },
  ],
  apiKey: "xyz",
});

// Function to search a specific index
const searchIndex = async (client, indexName, query, queryBy) => {
  return client.collections(indexName).documents().search({
    q: query,
    query_by: queryBy,
    highlight_full_fields: queryBy,
  });
};

// Function to search across multiple indexes
const searchAcrossIndexes = async (query) => {
  const auctionsResults = await searchIndex(
    auctionsClient,
    "auctions",
    query,
    "name, description, collection_name"
  );
  const collectionsResults = await searchIndex(
    collectionsClient,
    "collections",
    query,
    "name, symbol, description"
  );
  const nftsResults = await searchIndex(
    nftsClient,
    "nfts",
    query,
    "name, description, collection_name"
  );

  return {
    auctions: auctionsResults,
    collections: collectionsResults,
    nfts: nftsResults,
  };
};

export interface ISearchInput {
  sx?: SxProps;
  [key: string]: any;
}

export const GlobalSearch = ({ sx }: ISearchInput) => {
  const [selectedItem, setSelectedItem] = React.useState<any>(null);
  const [itemData, setItemData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [results, setResults] = React.useState({
    nfts: undefined,
    auctions: undefined,
    collections: undefined,
  });

  const dispatch = useAppDispatch();
  const { walletAddress } = useAppSelector((s) => s.auth);

  const handleSearch = async (event: any) => {
    const query = event.target.value;
    if (query) {
      setIsLoading(true);
      const searchResults = await searchAcrossIndexes(query);
      setResults(searchResults);
      setIsLoading(false);
    } else {
      setResults({ nfts: undefined, auctions: undefined, collections: undefined });
    }
  };

  const handleSelect = async (event: any, value: any) => {
    if (value) {
      setSelectedItem(value);
      setIsLoading(true);
      const response = await getNftDossier({
        walletAddress,
        contractAddress: value.document.collection_address,
        tokenId: value.document.token_id,
      });
      setItemData(response?.public_metadata.extension);
      setIsLoading(false);
    }
  };

  const limitHits = (hits, limit = 5) => hits.slice(0, limit);

  const allHits = [
    ...(results.auctions?.hits
      ? limitHits(results.auctions.hits).map((hit) => ({ ...hit, type: "Auctions" }))
      : []),
    ...(results.collections?.hits
      ? limitHits(results.collections.hits).map((hit) => ({ ...hit, type: "Collections" }))
      : []),
    ...(results.nfts?.hits
      ? limitHits(results.nfts.hits).map((hit) => ({ ...hit, type: "NFTs" }))
      : []),
  ];

  return (
    <>
      <div>
        <Autocomplete
          sx={sx}
          freeSolo
          size="small"
          disableClearable
          options={allHits}
          groupBy={(option) => option.type} // Group results by their type
          getOptionLabel={(option: any) => {
            if (option.type === "Auctions") return option.document.name || "";
            if (option.type === "Collections") return option.document.name || "";
            if (option.type === "NFTs") return option.document.name || "";
            return "";
          }}
          filterOptions={(options) => options}
          onChange={handleSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={handleSearch}
              InputProps={{
                ...params.InputProps,
                type: "search",
                startAdornment: <SearchIcon style={{ color: "#ccc" }} />,
              }}
            />
          )}
          renderOption={(props, option) => {
            const key = `${option.type}-${option.document.id}`;

            // Render highlighted text
            const getHighlightedText = (highlight) => {
              const snippet = highlight?.snippet || highlight?.value || "";
              const styledSnippet = snippet.replace(
                /<mark>(.*?)<\/mark>/g,
                '<span style="background-color: #FFD580; color: #000;">$1</span>'
              );
              return <span dangerouslySetInnerHTML={{ __html: styledSnippet }} />;
            };

            const foundItem = option.highlight?.name ? (
              <li {...props} key={key}>
                {option.type === "Auctions" && getHighlightedText(option.highlight?.name)}
                {option.type === "Collections" && getHighlightedText(option.highlight?.name)}
                {option.type === "NFTs" && getHighlightedText(option.highlight?.name)}
              </li>
            ) : (
              <li {...props} key={key}>
                {option.document.name}
              </li>
            );
            return foundItem;
          }}
          renderGroup={(params) => {
            const childrenArray = Children.toArray(params.children);
            if (childrenArray.length === 0) return null;
            return (
              <li key={params.key}>
                <Typography variant="subtitle1" sx={{ pl: 2 }}>
                  {params.group}
                </Typography>
                <ul>{childrenArray}</ul>
              </li>
            );
          }}
        />
      </div>
      <SlideDrawer open={!!selectedItem} toggleDrawer={() => setSelectedItem(null)}>
        {isLoading ? <p>Loading...</p> : <p>{JSON.stringify(itemData)}</p>}
      </SlideDrawer>
    </>
  );
};

export default GlobalSearch;
