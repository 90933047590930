import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Hidden } from "@mui/material";
import UserAvatar from "components/UserAvatar";
import CopiableAddress from "components/CopiableAddress";
import CampaignIcon from "@mui/icons-material/Campaign";
import GavelIcon from "@mui/icons-material/Gavel";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import { ROUTES } from "app/utils/constants/routes";
import { useAppSelector } from "features/store";

const listItems = [
  {
    label: "Auctions",
    path: ROUTES.DASHBOARD_AUCTIONS,
    icon: <CampaignIcon fontSize="small" />,
  },
  {
    label: "Bids",
    path: ROUTES.DASHBOARD_BIDS,
    icon: <GavelIcon fontSize="small" />,
  },
  {
    label: "Verifications",
    path: ROUTES.DASHBOARD_VERIFICATION,
    icon: <VerifiedUserIcon fontSize="small" />,
  },
  /* {
    label: "Notifications",
    path: ROUTES.DASHBOARD_NOTIFICATION,
    icon: <NotificationsIcon fontSize="small" />,
  }, */
];

export const DashboardSidebar = () => {
  const {
    auth: { walletName, walletAddress },
    user: {
      profileInfo: { username },
      // eslint-disable-next-line
      profileMedia: { profile_photo },
    },
  } = useAppSelector((state) => state);

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box sx={{ overflow: "auto" }}>
      <Box display="flex" justifyContent="center">
        <UserAvatar profile_photo={profile_photo} />
      </Box>
      <Box p={0.5} />
      <Link to="/profile" style={{ textDecoration: "none", paddingTop: 0 }}>
        <Typography gutterBottom align="center" variant="h6" color="secondary">
          {username !== "" ? username : walletName}
        </Typography>
      </Link>
      <Box display="flex" justifyContent="center">
        <CopiableAddress text={walletAddress} />
      </Box>
      <Box mt={3} mb={1}>
        <Divider />
      </Box>

      <List
        sx={{
          "& .Mui-selected": {
            borderLeft: "4px solid",
            borderColor: "primary.main",
          },
        }}
      >
        {listItems.map((item) => (
          <ListItem key={item.path} disablePadding sx={{ mb: 1 }}>
            <ListItemButton
              sx={{
                transition: "all 500ms linear",
                borderRadius: "0px 8px 8px 0px",
              }}
              selected={location.pathname.includes(item.path)}
              onClick={() => navigate(item.path)}
            >
              <ListItemIcon>{item?.icon}</ListItemIcon>
              <ListItemText secondary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
        <Hidden mdUp>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/")}>
              <ListItemText primary={<Typography align="left">Back Home</Typography>} />
            </ListItemButton>
          </ListItem>
        </Hidden>
      </List>
    </Box>
  );
};
