export const getKeplr = async () => {
  const sleep = (ms: number) => {
    return new Promise(function (resolve) {
      setTimeout(resolve, ms);
    });
  };

  while (!window.keplr || !window.getOfflineSigner || !window.getEnigmaUtils) {
    // eslint-disable-next-line
    await sleep(50);
  }

  // Enable Keplr.
  // This pops-up a window for the user to allow keplr access to the webpage.
  await window.keplr.enable(import.meta.env.VITE_REACT_APP_SECRET_CHAIN_ID);

  // Setup SecrtJS with Keplr's OfflineSigner
  // This pops-up a window for the user to sign on each tx we sent
  const keplrOfflineSigner = window.getOfflineSignerOnlyAmino(
    import.meta.env.VITE_REACT_APP_SECRET_CHAIN_ID
  );
  const { name: walletName } = await window.keplr.getKey(
    import.meta.env.VITE_REACT_APP_SECRET_CHAIN_ID
  );

  const [{ address: myAddress }] = await keplrOfflineSigner.getAccounts();

  return { address: myAddress, name: walletName };
};
