import React, { lazy } from "react";
import { RouteObject } from "react-router";
import { RoutesLoader } from "components/RoutesLoader";

// details pages
import LiveAuctionsDetails from "pages/marketplace/live-auctions-details";
import ClosedAuctionsDetails from "pages/marketplace/closed-auctions-details";
import UpcomingAuctionsDetails from "pages/marketplace/upcoming-auctions-details";

// pages
const MarketPlacePage = RoutesLoader(lazy(() => import("pages/marketplace")));

const marketplaceRoutes: RouteObject = {
  path: "/marketplace",
  element: <MarketPlacePage />,
  children: [
    { path: "live/:id", element: <LiveAuctionsDetails /> },
    { path: "closed/:id", element: <ClosedAuctionsDetails /> },
    { path: "upcoming/:id", element: <UpcomingAuctionsDetails /> },
  ],
};

export default marketplaceRoutes;
