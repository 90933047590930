import React from "react";
import { Box, Typography } from "@mui/material";

interface Props {
  text: string;
  link?: string | null;
  element?: any;
  height?: string;
}

const EmptyScreen = (props: Props) => {
  const { text, link = null, element = null, height = "60vh" } = props;
  return (
    <Box
      sx={{
        height,
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography sx={{ mb: 10 }} align="center" variant="h2" color="text.secondary">
        {text}
      </Typography>
      {link}
      {element}
    </Box>
  );
};

export default EmptyScreen;
