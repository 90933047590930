import React, { /* useState, */ createContext, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "features/store";
import { connectWallet, currentAuthenticatedUser } from "features/auth";
import { getProfileAndAddIfNotFound } from "features/users";
import { initCollections } from "features/collections";

// eslint-disable-next-line
interface AuthContextProps {}

const AuthContext = createContext<AuthContextProps | null>(null);

interface Props {
  children: any;
}

export const AuthProvider = (props: Props) => {
  const { children } = props;
  const { isAuthenticated } = useAppSelector((s) => s.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(connectWallet());
      dispatch(getProfileAndAddIfNotFound());
      dispatch(initCollections());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    const authenticate = async () => {
      await dispatch(connectWallet());
      dispatch(currentAuthenticatedUser());
    };
    authenticate();
  }, []);

  // eslint-disable-next-line
  return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>;
};

export default AuthContext;
