import React, { useEffect, useState } from "react";
import { isTokenApproved } from "app/utils/helpers";
import { P2P_ADDRESS } from "app/utils/constants/addresses";
import { useAppDispatch, useAppSelector } from "features/store";
import { approveNftAccess, revokeNftAccess } from "features/nfts";
import { getNftDossier } from "services/nfts/nftService";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { TextSmallPrimary } from "components/Typography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

type Props = {
  nft: any;
  received: boolean;
  setNftsApprovedForSwap?: (a?: any) => void;
};

export const NFT = (props: Props) => {
  const { nft, received = false, setNftsApprovedForSwap } = props;
  const [nftData, setNftData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isNFTApproved, setIsNFTApproved] = useState(false);
  const dispatch = useAppDispatch();

  const appDispatch = useAppDispatch();
  const { walletAddress } = useAppSelector((s) => s.auth);

  const handleApprove = async (e) => {
    setIsLoading(true);
    if (e.target.checked) {
      await appDispatch(
        approveNftAccess({
          spender: P2P_ADDRESS,
          collection_address: nft.token_addr,
          tokenId: nft.token_id,
          walletAddress,
        })
      );
      setIsNFTApproved(true);
      setIsLoading(false);
      if (setNftsApprovedForSwap) {
        setNftsApprovedForSwap((prev: any) => prev + 1);
      }
    } else {
      await appDispatch(
        revokeNftAccess({
          spender: P2P_ADDRESS,
          collection_address: nft.token_addr,
          tokenId: nft.token_id,
          walletAddress,
        })
      );
      setIsNFTApproved(false);
      setIsLoading(false);
      if (setNftsApprovedForSwap) {
        setNftsApprovedForSwap((prev) => prev - 1);
      }
    }
  };

  useEffect(() => {
    const getNftData = async () => {
      const nftDossier = await getNftDossier(walletAddress, nft.token_addr, nft.token_id);

      console.log("RES(-(-(-", nftDossier);
      setNftData({
        ...nftDossier.public_metadata.extension,
        image: nftDossier.public_metadata.extension.media[0].url,
        tokenApprovals: nftDossier.token_approvals,
        inventoryApprovals: nftDossier.inventory_approvals,
      });
      const approved = isTokenApproved(
        nftDossier.token_approvals,
        nftDossier.inventory_approvals,
        P2P_ADDRESS
      );
      setIsNFTApproved(approved);
      if (setNftsApprovedForSwap) {
        setNftsApprovedForSwap((prev: any) => (approved ? prev + 1 : prev));
      }
    };

    getNftData();
  }, [dispatch]);

  return !nftData ? (
    <Skeleton height={260} width={180} variant="rounded" />
  ) : (
    <Card variant="outlined" sx={{ width: "180px" }}>
      <CardMedia component="img" image={nftData.image} height="180" alt={nftData.name} />

      <CardContent sx={{ padding: "12px!important" }}>
        <Typography gutterBottom variant="subtitle2">
          {nftData.name}
        </Typography>

        <FormControlLabel
          control={
            isLoading ? (
              <CircularProgress size={16} sx={{ m: 1 }} />
            ) : (
              <Checkbox
                size="small"
                icon={<CheckCircleOutlineIcon sx={{ fontSize: "16px" }} />}
                checkedIcon={<CheckCircleOutlineIcon sx={{ fontSize: "16px" }} color="success" />}
                onChange={handleApprove}
                checked={isNFTApproved || received}
                disabled={received}
              />
            )
          }
          label={
            <TextSmallPrimary
              style={{
                color: received || isNFTApproved ? "green" : "gray",
              }}
            >
              Transferable
            </TextSmallPrimary>
          }
        />
      </CardContent>
    </Card>
  );
};
