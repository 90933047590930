import { LOADING } from "app/utils/constants/others";
import { Permit } from "secretjs";

interface Token {
  label: string;
  address: string;
  nativeBalance: string;
  wrappedSecretBalance: string;
  viewingKey: string;
  wrappedSecretPermit: Permit;
  symbol: string;
  hashCode: string;
  decimals: number;
}

interface Tokens {
  token: Token;
  loading: {
    swap: string;
    viewingKey: string;
    permit: string;
    nativeBalance: string;
    wrappedSecretBalance: string;
  };
  error: any;
}

export const initialState: Tokens = {
  token: {
    nativeBalance: "",
    wrappedSecretBalance: "",
    viewingKey: "",
    wrappedSecretPermit: {} as Permit,
    label: "test",
    symbol: "SSCRT",
    address: "secret1gvn6eap7xgsf9kydgmvpqwzkru2zj35ar2vncj",
    hashCode: "c74bc4b0406507257ed033caa922272023ab013b0c74330efc16569528fa34fe",
    decimals: 6,
  },

  loading: {
    swap: LOADING.IDLE,
    viewingKey: LOADING.IDLE,
    permit: LOADING.IDLE,
    nativeBalance: LOADING.IDLE,
    wrappedSecretBalance: LOADING.IDLE,
  },
  error: null,
};
