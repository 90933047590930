import { LOADING } from "app/utils/constants/others";
import { Collection } from "app/types/collections";

type Loading = {
  createCollection: string;
  listCollections: string;
  importCollection: string;
  createViewingKey: string;
  uploadImage: string;
};

interface InitialState {
  collections: Collection[];
  loading: Loading;
  error: unknown;
}

export const initialState: InitialState = {
  collections: [],
  loading: {
    createCollection: LOADING.IDLE,
    listCollections: LOADING.IDLE,
    importCollection: LOADING.IDLE,
    createViewingKey: LOADING.IDLE,
    uploadImage: LOADING.IDLE,
  },
  error: null,
};
