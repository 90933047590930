import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import useTheme from "@mui/material/styles/useTheme";
import blackLogo from "assets/logos/text-logo-black.png";
import whiteLogo from "assets/logos/text-logo-white.png";

const style = {
  height: "100px",
  display: { xs: "flex", lg: "none" },
};

interface MobileHeaderProps {
  handleDrawerToggle: () => void;
}

export const MobileHeader = (props: MobileHeaderProps) => {
  const { handleDrawerToggle } = props;
  const {
    palette: { mode },
  } = useTheme();

  return (
    <Toolbar sx={style}>
      <Link to="/">
        <img
          loading="eager"
          src={mode === "light" ? blackLogo : whiteLogo}
          width={150}
          alt="logo"
        />
      </Link>
      <Box flexGrow={1} />
      <IconButton
        size="large"
        color="primary"
        edge="end"
        aria-label="menu"
        onClick={handleDrawerToggle}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
    </Toolbar>
  );
};
