import React from "react";
import useSettings from "app/hooks/useSettings";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { useAppDispatch, useAppSelector } from "features/store";
import { IconButton, Typography } from "@mui/material";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import DraftsIcon from "@mui/icons-material/Drafts";
import { readNotification } from "features/users";
import { useNavigate } from "react-router";

export const Notifications = () => {
  const { notificationsOpen, setNotificationsOpen } = useSettings();
  const { notifications, unreadNotificationCount } = useAppSelector((s) => s.user);

  const notificationList = React.useMemo(() => {
    const copy = [...notifications];
    return copy.reverse();
  }, [notifications]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClickNotification = (notif) => () => {
    if (!notif.is_read) {
      dispatch(readNotification(notif.id));
    }

    if (notif.url) {
      navigate(notif.url);
    }
    setNotificationsOpen(false);
  };

  const handleClickRead = (notif) => (e) => {
    e.stopPropagation();

    if (!notif.is_read) {
      dispatch(readNotification(notif.id));
    }
  };

  return (
    <Drawer
      sx={{ zIndex: 90000 }}
      anchor="right"
      open={notificationsOpen}
      onClose={() => setNotificationsOpen(false)}
    >
      <Box
        sx={{
          minWidth: "320px",
          pt: 2,
          backgroundColor: "background.paper",
          height: "100%",
          overflowY: "scroll",
          "& .MuiSvgIcon-root": { fontSize: "16px" },
        }}
      >
        <Typography align="center" my={2}>
          Unread ( {unreadNotificationCount} )
        </Typography>
        <List>
          {notificationList?.map((notif) => (
            <ListItem
              key={notif.id}
              onClick={handleClickNotification(notif)}
              sx={{ backgroundColor: !notif.is_read && "background.default" }}
              disablePadding
            >
              <ListItemButton>
                <ListItemText
                  primary={<Typography variant="body2">{notif.message}</Typography>}
                  secondary={
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontSize="12px"
                      fontWeight="400"
                    >
                      {notif.created_at.replace("T", " - ").split(".")[0]}
                    </Typography>
                  }
                />
                <IconButton size="small" onClick={handleClickRead(notif)}>
                  {notif.is_read ? (
                    <DraftsIcon fontSize="small" />
                  ) : (
                    <MarkunreadIcon fontSize="small" />
                  )}
                </IconButton>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};
