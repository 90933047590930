import { LOADING } from "app/utils/constants/others";
import { Auction } from "app/types";
import { PlacedBidModel } from "app/models/placedBid";

interface Loading {
  createAuction: string;
  getAuctionInfo: string;
  isAuctionOwner: string;
  isAuctionHasBids: string;
  placeBid: string;
  changeMinimumBid: string;
  retractBid: string;
  getPlacedBid: string;
  verifyAuction: string;
  saveFailedAuction: string;
  finalizeAuction: string;
  myAuctions: string;
  unverified: string;
}

const loading = {
  createAuction: LOADING.IDLE,
  getAuctionInfo: LOADING.IDLE,
  isAuctionOwner: LOADING.IDLE,
  isAuctionHasBids: LOADING.IDLE,
  placeBid: LOADING.IDLE,
  changeMinimumBid: LOADING.IDLE,
  retractBid: LOADING.IDLE,
  getPlacedBid: LOADING.IDLE,
  verifyAuction: LOADING.IDLE,
  saveFailedAuction: LOADING.IDLE,
  finalizeAuction: LOADING.IDLE,
  myAuctions: LOADING.IDLE,
  unverified: LOADING.IDLE,
};

interface InitialStateProps {
  allAuctions: any;
  selectedAuction: any;
  liveAuctions: any;
  pendingAuctions: any;
  closedAuctions: any;
  activeBids: any;
  wonBids: any;
  unverifiedAuctions: any;
  verifiedByMeAuctions: any;
  auction: any;
  isOwner: boolean;
  hasBids: boolean;
  loading: Loading;
  placedBid: PlacedBidModel | null;
  errors: any;
}

export const initialAuction: Auction = {
  label: "",
  sell_contract: {
    code_hash: "",
    address: "",
  },
  sell_nft_token_id: "",
  bid_contract: {
    code_hash: "c74bc4b0406507257ed033caa922272023ab013b0c74330efc16569528fa34fe",
    address: "secret1gvn6eap7xgsf9kydgmvpqwzkru2zj35ar2vncj",
  },
  minimum_bid: "",
  starts_at: new Date().getTime() + 259200000,
  duration: 12, // in hours - to be converted to seconds before submition
  description: "",
};

export const initialState: InitialStateProps = {
  allAuctions: null,
  selectedAuction: null,
  liveAuctions: null,
  pendingAuctions: null,
  closedAuctions: null,
  activeBids: null,
  wonBids: null,
  unverifiedAuctions: [],
  verifiedByMeAuctions: [],
  auction: null,
  isOwner: false,
  hasBids: false,
  placedBid: null,
  loading,
  errors: null,
};
