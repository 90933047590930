import { createSlice } from "@reduxjs/toolkit";
import { AuctionModel } from "app/models/auction";
import { LOADING } from "app/utils/constants/others";
import { toast } from "react-toastify";
import { initialState } from "./state";

import {
  listMyAuctions,
  listUnverifiedAuctions,
  createAuction,
  getAuctionInfo,
  isAuctionOwner,
  isAuctionHasBids,
  placeBid,
  changeMinimumBid,
  retractBid,
  getPlacedBid,
  verifyAuction,
  saveFailedAuction,
  finalizeAuction,
} from "./reducers";

const auctionsSlice = createSlice({
  name: "auctions",
  initialState,
  reducers: {
    selectAuction: (state, action) => {
      state.selectedAuction = action.payload;
    },
    resetAuctionDetails: (state) => {
      state.auction = null;
    },
    listMyPendingAuctions: (state) => {
      state.pendingAuctions = state?.allAuctions?.unverified?.as_seller ?? [];
    },
    listMyLiveAuctions: (state) => {
      state.liveAuctions = state?.allAuctions?.active?.as_seller ?? [];
    },
    listMyClosedAuctions: (state) => {
      state.closedAuctions = state?.allAuctions?.closed?.as_seller ?? [];
    },
    listMyActiveBids: (state) => {
      state.activeBids = state?.allAuctions?.active?.as_bidder ?? [];
    },
    listMyWonBids: (state) => {
      state.wonBids = state?.allAuctions?.closed?.won ?? [];
    },
    listVerifiedByMeAuctions: (state) => {
      state.verifiedByMeAuctions = state?.allAuctions?.unverified?.as_verifier ?? [];
    },
    addVerifiedByMe: (state, action) => {
      state.verifiedByMeAuctions.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listMyAuctions.pending, (state) => {
      state.loading.myAuctions = LOADING.PENDING;
    });
    builder.addCase(listMyAuctions.fulfilled, (state, action) => {
      state.allAuctions = action.payload;
      state.loading.myAuctions = LOADING.SUCCEEDED;
    });
    builder.addCase(listMyAuctions.rejected, (state, action) => {
      state.errors = action.error;
      state.loading.myAuctions = LOADING.FAILED;
    });

    builder.addCase(listUnverifiedAuctions.pending, (state) => {
      state.loading.unverified = LOADING.PENDING;
    });
    builder.addCase(listUnverifiedAuctions.fulfilled, (state, action) => {
      state.unverifiedAuctions = action.payload;
      state.loading.unverified = LOADING.SUCCEEDED;
    });
    builder.addCase(listUnverifiedAuctions.rejected, (state, action) => {
      state.errors = action.error;
      state.loading.unverified = LOADING.FAILED;
    });

    builder.addCase(createAuction.pending, (state) => {
      state.loading.createAuction = LOADING.PENDING;
    });
    builder.addCase(createAuction.fulfilled, (state) => {
      state.loading.createAuction = LOADING.SUCCEEDED;
      toast.success("Auction successfully created ");
    });
    builder.addCase(createAuction.rejected, (state, action) => {
      state.loading.createAuction = LOADING.FAILED;
      state.errors = action.error;
      toast.error(action.error.message);
    });

    builder.addCase(getAuctionInfo.pending, (state) => {
      state.loading.getAuctionInfo = LOADING.PENDING;
    });
    builder.addCase(getAuctionInfo.fulfilled, (state, action) => {
      const auction = new AuctionModel(action.payload);
      state.auction = auction;
      state.loading.getAuctionInfo = LOADING.SUCCEEDED;
    });
    builder.addCase(getAuctionInfo.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading.getAuctionInfo = LOADING.FAILED;
    });

    builder.addCase(getPlacedBid.fulfilled, (state, action) => {
      if ((action.payload as any).status === "Success") {
        (state as any).placedBid = action.payload;
      }
    });
    builder.addCase(isAuctionOwner.fulfilled, (state, action) => {
      state.isOwner = action.payload;
    });
    builder.addCase(isAuctionOwner.rejected, (state, action) => {
      state.errors = action.error;
    });

    builder.addCase(isAuctionHasBids.fulfilled, (state, action) => {
      if (action.payload) {
        state.hasBids = !!action.payload;
      }
    });
    builder.addCase(isAuctionHasBids.rejected, (state, action) => {
      state.errors = action.error;
    });

    builder.addCase(placeBid.pending, (state) => {
      state.loading.placeBid = LOADING.PENDING;
    });
    builder.addCase(placeBid.fulfilled, (state) => {
      state.loading.placeBid = LOADING.SUCCEEDED;
      toast.success("Bid successfully placed.");
    });
    builder.addCase(placeBid.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading.placeBid = LOADING.FAILED;
      toast.error("Rejected !");
    });

    builder.addCase(changeMinimumBid.pending, (state) => {
      state.loading.changeMinimumBid = LOADING.PENDING;
    });
    builder.addCase(changeMinimumBid.fulfilled, (state, action) => {
      state.auction.minimum_bid = action.payload;
      state.loading.changeMinimumBid = LOADING.SUCCEEDED;
      toast.success("Bid successfully changed.");
    });
    builder.addCase(changeMinimumBid.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading.changeMinimumBid = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(finalizeAuction.pending, (state) => {
      state.loading.finalizeAuction = LOADING.PENDING;
    });
    builder.addCase(finalizeAuction.fulfilled, (state) => {
      state.loading.finalizeAuction = LOADING.SUCCEEDED;
      toast.success("Auction successfully finalized.");
    });
    builder.addCase(finalizeAuction.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading.finalizeAuction = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(retractBid.pending, (state) => {
      state.loading.retractBid = LOADING.PENDING;
    });
    builder.addCase(retractBid.fulfilled, (state) => {
      state.loading.retractBid = LOADING.SUCCEEDED;
      state.placedBid = null;
    });
    builder.addCase(retractBid.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading.retractBid = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(verifyAuction.pending, (state) => {
      state.loading.verifyAuction = LOADING.PENDING;
    });
    builder.addCase(verifyAuction.fulfilled, (state) => {
      state.loading.verifyAuction = LOADING.SUCCEEDED;
      toast.success("Auction successfully verified.");
    });
    builder.addCase(verifyAuction.rejected, (state, action) => {
      state.errors = action.error;
      state.loading.verifyAuction = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(saveFailedAuction.rejected, (state, action) => {
      state.errors = action.error;
      toast.error(action.error.message);
    });
  },
});

export {
  listMyAuctions,
  listUnverifiedAuctions,
  createAuction,
  getAuctionInfo,
  isAuctionOwner,
  isAuctionHasBids,
  placeBid,
  changeMinimumBid,
  retractBid,
  getPlacedBid,
  verifyAuction,
  saveFailedAuction,
  finalizeAuction,
};

export const {
  selectAuction,
  resetAuctionDetails,
  listMyPendingAuctions,
  listMyLiveAuctions,
  listMyClosedAuctions,
  listMyActiveBids,
  listMyWonBids,
  listVerifiedByMeAuctions,
  addVerifiedByMe,
} = auctionsSlice.actions;
export default auctionsSlice.reducer;
