import {
  GlobalCollection,
  CreateCollectionValues,
  CollectionDocument,
  CollectionDocumentAssets,
  CollectionDocumentAsset,
} from "app/types/collections";
import { getCollectionInitMsg } from "./helpers";
import { Snip721 } from "secretjs-service/dist/esm/contracts";
import { uploadCollectionMedia } from "services/uploads/uploadService";
import { Collection } from "app/types/collections";
import { API, API_PUBLIC } from "app/libs/api";
import { SNIP721_CODE_ID } from "app/utils/constants/addresses";

export const getGlobalCollection = async (collectionAddress: string): Promise<GlobalCollection> => {
  const { data } = await API_PUBLIC.get(`/global-collections/${collectionAddress}`);
  return data;
};

export const getCollectionDocument = async (
  collectionAddress: string
): Promise<CollectionDocument> => {
  const { data } = await API_PUBLIC.get(`/index/collections/${collectionAddress}`);
  return data.collection;
};

export const getCollectionDocumentAssets = async (
  collectionAddress: string
): Promise<CollectionDocumentAssets> => {
  const { data } = await API_PUBLIC.get(`/index/collections/${collectionAddress}/assets`);
  return data;
};

export const getCollectionDocumentAsset = async (
  collectionAddress: string,
  token: string
): Promise<CollectionDocumentAsset> => {
  const { data } = await API_PUBLIC.get(`/index/collections/${collectionAddress}/assets/${token}`);
  return data;
};

export const indexCollection = async (address: string) => {
  const res = await API.post(`/index/collections/${address}`);
  return res;
};

export const getTrendingCollections = async (): Promise<GlobalCollection[]> => {
  const response = await API_PUBLIC.get("/global-collections");
  const data = response.data.data;
  const collections: GlobalCollection[] = data.slice(0, 6).map((col: any) => col.attributes);
  return collections;
};

export async function prepareAndSaveCollection(
  address: string,
  { collectionLogo, collectionCover, formData }: CreateCollectionValues
): Promise<{ userCollections: Collection[]; collectionAddress: string }> {
  const initMsg = getCollectionInitMsg(address, formData);
  const snip721 = new Snip721(address);
  const collectionAddress = await snip721.initContract(initMsg);

  // Upload media files
  const coverImgUrl = collectionCover ? await uploadCollectionMedia(collectionCover) : "";
  const logoImgUrl = collectionLogo ? await uploadCollectionMedia(collectionLogo) : "";

  // Query token supply
  const total_supply = await snip721.getContractNumTokens(collectionAddress);

  // Prepare payload
  const payload: Collection = {
    contract_code_id: Number(SNIP721_CODE_ID),
    contract_address: collectionAddress,
    contract_token_type: "SNIP-721",
    contract_blockchain: "Secret Network",
    config_token_supply_is_public: initMsg.init_msg.config.public_token_supply,
    config_owner_is_public: initMsg.init_msg.config.public_owner,
    config_sealed_metadata_is_enabled: initMsg.init_msg.config.enable_sealed_metadata,
    config_unwrapped_metadata_is_private: initMsg.init_msg.config.unwrapped_metadata_is_private,
    config_minter_may_update_metadata: initMsg.init_msg.config.minter_may_update_metadata,
    config_owner_may_update_metadata: initMsg.init_msg.config.owner_may_update_metadata,
    config_burn_is_enabled: initMsg.init_msg.config.enable_burn,
    creator_address: address,
    total_supply,
    name: initMsg.init_msg.name,
    symbol: initMsg.init_msg.symbol,
    description: formData.description,
    logo_img: logoImgUrl,
    cover_img: coverImgUrl,
    created_date: new Date(),
    ...(initMsg.init_msg.royalty_info ? initMsg.init_msg.royalty_info : {}),
  };

  // Save to database
  await API.post("/global-collections", { data: payload });

  const userCollections = await saveCollection(address, payload);

  // Return users collections + newly created collection address
  return { userCollections, collectionAddress };
}

export const saveCollection = async (
  walletAddress: string,
  newCollection: Partial<Collection>
): Promise<Collection[]> => {
  const {
    data: { collections = [] },
  } = await API.get(`/collections/${walletAddress}`);

  const newCollections = {
    owner: walletAddress,
    collections: [...collections, newCollection],
  };

  const { data } = await API.put(`/collections/${walletAddress}`, newCollections);
  return data.collections;
};

export const getContractConfig = async (contractAddress: string) => {
  try {
    const snip721 = new Snip721();
    const config = await snip721.getContractConfig(contractAddress);
    return config;
  } catch (error) {
    console.log("errr", error);
  }
};
