/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Profile } from "app/types";
import { addressValidator } from "app/utils/helpers/index";
import { getKeplr } from "app/utils/keplr";
import { ViewinKeyManager } from "app/utils/helpers/viewingKeyManager";
import { API } from "app/libs/api";
import { Snip721 } from "secretjs-service/dist/esm/contracts";
import { uploadProfileMedia } from "./helpers";

export const getProfileInfo = async () => {
  let profile: any | "notFound";
  try {
    const { address } = await getKeplr();
    const { data } = await API.get(`/profiles/${address}`);
    profile = data;
  } catch (error) {
    if ((error as any).request.status === 404) {
      profile = "notFound";
    } else {
      console.error(error);
    }
  }
  return profile;
};

const addProfile = async () => {
  const { address } = await getKeplr();
  const profile: Profile = {
    username: "",
    wallet_address: address,
    telegram_handle: "",
    twitter_handle: "",
    notification_active: false,
  };
  const {
    data: { data },
  } = await API.post("/profiles", { data: { ...profile } });

  return { id: data.id, ...data.attributes };
};

export const getProfileAndAddIfNotFound = createAsyncThunk<unknown>("get-profile", async () => {
  let profile = await getProfileInfo();

  if (profile === "notFound") {
    profile = await addProfile();
  }
  return profile;
});

export const updateProfile = createAsyncThunk<
  any,
  { profile: any; id: string | number }
  // eslint-disable-next-line
>("update-profile", async ({ profile, id }, { rejectWithValue }) => {
  try {
    const data = { data: { ...profile } };
    await API.put(`/profiles/${id}`, data);
    return profile;
  } catch (error) {
    throw new Error((error as any).response.data.error.name);
  }
});

export const uploadMedia = createAsyncThunk<
  { field: string; url: string },
  { img: File; profileId: string | number; field: string }
>("upload-media", async ({ img, profileId, field }) => {
  const res = await uploadProfileMedia(img, profileId, field);
  return {
    field,
    url: res.data[0].url,
  };
});

export const calculateUserEvaluation = async () => {
  try {
    const { address } = await getKeplr();
    await API.post(`/karmas/${address}/precalculate`, {});
    await API.post(`/karmas/${address}/calculate`, {});
  } catch (error) {
    console.error(error);
  }
};

export const getUserEvaluation = createAsyncThunk<unknown>("get-evaluation", async () => {
  const { address } = await getKeplr();
  await calculateUserEvaluation();
  const { data } = await API.get(`/karmas/${address}`);
  return data;
});

export const listUserCollections = createAsyncThunk("list-user-collections", async () => {
  const { address } = await getKeplr();
  const res = await API.get(`/collections/${address}`);
  return res.data;
});

export const getContractInfo = async (address: string) => {
  const snip721 = new Snip721();
  // eslint-disable-next-line
  const { ContractInfo } = await snip721.getContractInfo(address);
  return ContractInfo;
};

export const saveCollection = async (walletAddress, newCollelction) => {
  const {
    data: { collections },
  } = await API.get(`/collections/${walletAddress}`);

  if (!collections) throw new Error("collections does not exist !");

  const isAlreadyExisting = collections.find(
    (col) => col.collection_address === newCollelction.collection_address
  );

  if (isAlreadyExisting) throw new Error("collections already exists !");

  const data = {
    owner: walletAddress,
    collections: [...collections, newCollelction],
  };

  const updated = await API.put(`/collections/${walletAddress}`, data);
  return updated;
};

export const importCollection = createAsyncThunk<any, any>(
  "import-collection",
  async ({ collectionAddress, walletAddress }) => {
    const collectionInfo = await getContractInfo(collectionAddress);

    const data = {
      collection_address: collectionAddress,
      collection_name: collectionInfo.name,
      description: "",
      cover_img: "",
      logo_img: "",
    };

    const {
      data: { collections },
    } = await saveCollection(walletAddress, data);
    return collections;

    // return null;
  }
);

export const uploadCollectionMedia = async (imageFile: File) => {
  const formData = new FormData();
  formData.append("files", imageFile);
  const { data } = await API.post("/upload", formData);
  return data[0].url;
};

export const createViewingKey = createAsyncThunk<string, any>(
  "createViewingKey",
  async ({ collection_address, walletAddress }) => {
    if (!addressValidator(collection_address)) {
      throw new Error("invalid address given!");
    }

    const existingKey = await ViewinKeyManager.getKey(collection_address);
    if (existingKey) {
      return existingKey;
    }

    const snip721 = new Snip721(walletAddress);
    const key = await snip721.createViewingKey(collection_address);
    ViewinKeyManager.add(walletAddress, collection_address, key);

    return key;
  }
);

export const listNotifications = createAsyncThunk("list-notifications", async () => {
  const { address } = await getKeplr();
  const { data } = await API.get(`/notifications/${address}`);
  let unreadCount = 0;
  if (data?.notifications) {
    data?.notifications.forEach((row) => {
      if (!row.is_read) {
        unreadCount += 1;
      }
    });
  }

  return [data.notifications ? data.notifications : [], unreadCount];
});

export const readNotification = createAsyncThunk<string, string>(
  "read-notifications",
  async (id) => {
    const { address } = await getKeplr();
    await API.put(`/notifications/${address}/read/${id}`);
    return id;
  }
);

interface NotificationData {
  address: string;
  notification: {
    message: string;
    url: string;
  };
}
export const sendNotification = createAsyncThunk<boolean, NotificationData>(
  "sendNotification",
  async ({ address, notification }) => {
    await API.post(`/notifications/${address}`, { ...notification });
    return true;
  }
);
