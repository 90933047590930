const components = {
  MuiCard: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.1) 0px 10px 50px" : "none",
      }),
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.1) 0px 10px 50px" : "none",
      }),
    },
  },

  MuiAppBar: {
    styleOverrides: {
      root: {
        zIndex: 10000,
        boxShadow: "none",
        borderRadius: 0,
      },
      colorInherit: {
        backgroundColor: "#fff",
        zIndex: 9000,
      },
    },
  },

  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: 14,
      },
    },
  },

  MuiOutlinedInput: {
    defaultProps: {
      size: "small",
    },
    styleOverrides: {
      root: {
        fontSize: 13,
        borderRadius: "6px",
        "&:hover .MuiOutlinedInput-notchedOutline": {},
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px",
        },
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        boxShadow: "none",
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      disableElevation: true,
      size: "large",
    },
    styleOverrides: {
      root: () => ({
        textTransform: "none",
        fontSize: 14,
        fontWeight: 500,
        borderRadius: "6px",
        paddingLeft: 24,
        paddingRight: 24,
      }),

      containedPrimary: {
        color: "#fff",
        boxShadow: "1px 1px 6px #f57322",
      },
      outlinedPrimary: {
        boxShadow: "0px 0px 4px #f57322",
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      defaultProps: {
        size: "small",
      },
      root: {
        fontWeight: 600,
        fontSize: "12px!important",
        padding: "0px 4px",
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        height: "40px!important",
        fontSize: "14px",
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        borderRadius: "4px",
      },
    },
  },
};

export default components;
