/* eslint-disable */
import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const CustomCircularProgress = (props) => {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        sx={{
          color: (theme) =>
            props.type === "approved"
              ? "#2e7d32"
              : props.type === "disapproved"
              ? "#F00"
              : theme.palette.primary.light,
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        {...props}
      />
    </Box>
  );
};

const CircularProgressWithLabel = (props) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CustomCircularProgress
        thickness={props.thickness}
        size={props.size}
        variant="determinate"
        value={props.value}
        type={props.type}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: props.variant === "verification" ? 0 : -14,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#ccc",
        }}
      >
        {props.variant === "verification" ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="h5"
              component="div"
              color={props.type === "approved" ? "#2e7d32" : "#F00"}
            >
              {props.verification}
            </Typography>
            <Typography variant="body2" color={props.type === "approved" ? "#2e7d32" : "#F00"}>
              {props.type}
            </Typography>
          </Box>
        ) : (
          <Typography variant="h4" component="div">
            {`${Math.round(props.value)}`}
            <span style={{ fontSize: "16px" }}>%</span>
          </Typography>
        )}
      </Box>
    </Box>
  );
};

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function PercentageProgress(props) {
  const { progress, size = 120, thickness = 1, variant = null, verification, type } = props;
  return (
    <Box
      className="progress"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "32px 0px",
      }}
    >
      <CircularProgressWithLabel
        verification={verification}
        variant={variant}
        type={type}
        thickness={thickness}
        size={size}
        value={Number(progress)}
      />
    </Box>
  );
}
