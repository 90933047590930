import { createTheme } from "@mui/material/styles";
import typography from "./typography";
import { lightTheme, darkTheme } from "./palettes";
import components from "./components";

const getTheme = (mode) => {
  const theme = createTheme({
    typography,
    palette: mode === "dark" ? darkTheme : lightTheme,
    components,
    shape: {
      borderRadius: 10,
    },
  });
  return theme;
};

export default getTheme;
