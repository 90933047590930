import React, { useState, createContext, useReducer, useEffect, useMemo, useCallback } from "react";
import { ThemeProvider } from "@mui/material/styles";
import Dialog from "components/Dialog";
// @ts-ignore
import getTheme from "theme";
import axios from "axios";
import { getItemFromLS, saveItemToLS } from "app/utils/helpers/localstorage";
import { useAppDispatch, useAppSelector } from "features/store";
import { getNativeBalance, getWrappedSecretBalance } from "features/tokens";
import { API } from "app/libs/api";
import { dialogReducer, dialogInitState } from "./reducers";

const TOKEN_INFO_API = "https://api.coingecko.com/api/v3/simple/price?ids=secret&vs_currencies=USD";

const SettingsContext = createContext<any>(null);

interface Props {
  children: any;
}

export const SettingsProvider = (props: Props) => {
  const { children } = props;
  // @ts-ignore
  const [dialogProps, dispatch] = useReducer(dialogReducer, dialogInitState);

  const [view, setView] = useState("list");
  const [auctionType, setAuctionType] = useState("live");
  const [themeMode, setThemeMode] = useState("light");
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [scrtTokenInfo, setScrtTokenInfo] = useState(null);
  const appDispatch = useAppDispatch();
  const { isWalletConnected, walletAddress, isAuthenticated } = useAppSelector((s) => s.auth);
  const theme = useMemo(() => getTheme(themeMode), [themeMode]);

  const getScrtTokenInfo = async () => {
    await axios
      .get(TOKEN_INFO_API)
      .then((res) => setScrtTokenInfo(res.data))
      // @ts-ignore
      .catch(() => setScrtTokenInfo("error"));
  };

  const switchThemeMode = useCallback(() => {
    setThemeMode((prev) => (prev === "light" ? "dark" : "light"));
  }, []);

  const clearDialogProps = () => {
    // @ts-ignore
    dispatch({ type: "CLEAR" });
  };

  const detectThemeMode = () => {
    const currentThemeMode = getItemFromLS("acti-theme");
    if (currentThemeMode) {
      setThemeMode(currentThemeMode);
    } else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setThemeMode("dark");
    }
  };

  const initCollections = async (wAddress: string) => {
    const data = {
      data: { owner: wAddress, collections: [] },
    };
    try {
      const res = await API.get(`/collections/${wAddress}`);
      if (!res.data) {
        await API.post(`/collections`, data);
      }
    } catch (error) {
      if ((error as any).response.status === 404) {
        await API.post(`/collections`, data);
        return;
      }
      throw error;
    }
  };

  useEffect(() => {
    detectThemeMode();
  }, []);

  useEffect(() => {
    saveItemToLS("acti-theme", themeMode);
  }, [themeMode]);

  useEffect(() => {
    // appDispatch(initialAuthLoad(LOADING.PENDING));
    if (isWalletConnected) {
      getScrtTokenInfo();
      appDispatch(getNativeBalance());
      // appDispatch(getWrappedSecretBalance({ walletAddress }));
    }
    if (isAuthenticated) {
      initCollections(walletAddress);
    }
  }, [isWalletConnected]);

  useEffect(() => {
    window.addEventListener("keplr_keystorechange", () => {
      // @ts-ignore
      dispatch({ type: "ACCOUNT_SWITCH", payload: { clearDialogProps } });
    });

    return () =>
      window.removeEventListener("keplr_keystorechange", () => console.log("listener removed"));
  }, []);

  const settings = {
    mobileFilterOpen,
    setMobileFilterOpen,
    view,
    setView,
    scrtTokenInfo,
    switchThemeMode,
    auctionType,
    setAuctionType,
    notificationsOpen,
    setNotificationsOpen,
  };

  return (
    // eslint-disable-next-line
    <SettingsContext.Provider value={{ ...settings }}>
      <ThemeProvider theme={theme}>
        {children}
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        {dialogProps && <Dialog {...dialogProps} />}
      </ThemeProvider>
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
