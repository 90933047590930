import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";

import auctionsReducer from "./auctions";
import collectionsReducer from "./collections";
import nftsReducer from "./nfts";
import swapReducer from "./swap";
import usersReducer from "./users";
import authReducer from "./auth";

import tokensReducer from "./tokens";

const store = configureStore({
  reducer: {
    auctions: auctionsReducer,
    collections: collectionsReducer,
    nfts: nftsReducer,
    swap: swapReducer,
    user: usersReducer,
    auth: authReducer,
    tokens: tokensReducer, // temprary
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();

export default store;
