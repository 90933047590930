import * as React from "react";
import { UpcomingAuctionDetailsSlider } from "modules/marketplace/UpcomingAuctionsDetailsSlider";
import { Helmet } from "react-helmet";

const UpcomingAuctionsDetails = () => (
  <>
    <Helmet>
      <title>Marketplace | Details</title>
    </Helmet>
    <UpcomingAuctionDetailsSlider />
  </>
);

export default UpcomingAuctionsDetails;
