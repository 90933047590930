import { saveItemToLS, getItemFromLS } from "app/utils/helpers/localstorage";
import { getKeplr } from "app/utils/keplr";

interface AuthLS {
  address: string;
  jwt: string;
}

export const getExistingAuthLS = (walletAddress: string) => {
  const currentJwts = getItemFromLS("acti-jwt") ?? undefined;

  if (!currentJwts) throw new Error("No logged user !");
  const existing: AuthLS = currentJwts.find((item: AuthLS) => item.address === walletAddress);

  return existing?.jwt;
};

export const setAuthLS = async (jwt: string) => {
  const { address } = await getKeplr();
  const currentJwts = getItemFromLS("acti-jwt") ?? undefined;

  if (!currentJwts) {
    saveItemToLS("acti-jwt", [{ address, jwt }]);
  } else {
    saveItemToLS("acti-jwt", [...currentJwts, { address, jwt }]);
  }
};

export const clearAuthLS = async () => {
  const { address } = await getKeplr();
  const currentJwts = getItemFromLS("acti-jwt") ?? undefined;
  const currentVks = getItemFromLS("acti-keys") ?? undefined;

  const updatedJwts = currentJwts.filter((item: AuthLS) => item.address !== address);
  saveItemToLS("acti-jwt", updatedJwts);

  const updatedVks = currentVks.filter((item: AuthLS) => item.address !== address);
  saveItemToLS("acti-keys", updatedVks);
};
