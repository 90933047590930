import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "features/store";
import { Box, Skeleton, Typography } from "@mui/material";
import { TextSmallSecondary } from "components/Typography";
import { bech32 } from "app/utils/constants";
import { getNftDossier } from "services/nfts/nftService";
import { Metadata } from "secretjs/dist/extensions/snip721/types";

type Props = {
  nft: any;
};

export const NFT = (props: Props) => {
  const { nft } = props;
  const [nftData, setNftData] = useState<Metadata>({});

  const dispatch = useAppDispatch();
  const { walletAddress, isAuthenticated } = useAppSelector((s) => s.auth);

  useEffect(() => {
    const fetchNftDossier = async () => {
      try {
        const nftDossier = await getNftDossier(walletAddress, nft.token_addr, nft.token_id);
        setNftData(nftDossier.public_metadata.extension);
      } catch (error) {
        console.error("Error fetching NFT dossier:", error);
      }
    };

    fetchNftDossier();
  }, [dispatch, isAuthenticated]);

  return (
    <Box display="flex" gap={1}>
      {nftData === null ? (
        <Skeleton width={80} height={80} variant="rounded" />
      ) : (
        <img
          src={nftData?.media[0].url}
          alt="nft"
          style={{ height: "80px", width: "80px", borderRadius: "12px" }}
        />
      )}
      <Box>
        {nftData === null ? (
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        ) : (
          <Typography>{nftData?.name}</Typography>
        )}
        <Box mt={0.5}>
          <TextSmallSecondary>collection :</TextSmallSecondary>
          <TextSmallSecondary>{bech32(nft.token_addr, 24)}</TextSmallSecondary>
        </Box>
      </Box>
    </Box>
  );
};
