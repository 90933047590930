/* eslint-disable */
import React from "react";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";

const Status = {
  PENDING: "pending",
  LOADING: "loading",
  LOADED: "loaded",
};

export default class ImageProgress extends React.Component {
  constructor(props) {
    // const status = props.src ? Status.LOADING : Status.PENDING
    super(props);
    this.state = {
      status: props.src ? Status.LOADING : Status.PENDING,
      progress: 0,
    };
    this.error = false;
  }

  componentDidMount() {
    if (this.state.status === Status.LOADING) this.resolveImage();
  }

  componentWillReceiveProps(nextProps) {
    const that = this;
    if (this.props.src !== nextProps.src) {
      // const status = nextProps.src ? Status.LOADING : Status.PENDING
      this.setState(
        {
          status: nextProps.src ? Status.LOADING : Status.PENDING,
        },
        () => that.resolveImage()
      );
    }
  }

  onError(event) {
    console.error("Error :", event);
    this.error = true;
  }

  onProgress(event) {
    const progress = parseInt((event.loaded / event.total) * 100);
    this.setState({
      progress,
    });
  }

  onLoad(blob) {
    this.setState({
      blob: window.URL.createObjectURL(blob),
      status: Status.LOADED,
    });
  }

  componentWillUnmount() {
    if (this.xmlHTTP) this.xmlHTTP.abort();
  }

  resolveImage() {
    // Abort prev request if active
    if (this.xmlHTTP) this.xmlHTTP.abort();

    this.xmlHTTP = new XMLHttpRequest();

    this.xmlHTTP.open("GET", this.props.src, true);
    this.xmlHTTP.responseType = "arraybuffer";

    this.xmlHTTP.onerror = (event) => this.onError(event);
    this.xmlHTTP.onprogress = (event) => this.onProgress(event);
    this.xmlHTTP.onload = () => this.onLoad(new Blob([this.xmlHTTP.response]));

    this.xmlHTTP.send();
  }

  render() {
    if (this.state.status === Status.LOADING) {
      return this.props.children(this.state.progress);
    }
    if (this.error) {
      return <BrokenImageIcon sx={{ color: "gray" }} />;
    }

    if (this.state.status === Status.LOADED) {
      return <img loading="lazy" src={this.state.blob} className={this.props.className} alt="" />;
    }

    if (this.state.status === Status.PENDING) {
      return this.props.children("pending");
    }

    return <p>Loading...</p>;
  }
}
