import React, { lazy } from "react";
import { RouteObject, Navigate } from "react-router";
import { RoutesLoader } from "components/RoutesLoader";
import { OffersReceived } from "modules/acti-swap/offers/OffersLists/OffersReceived";
import { OffersSent } from "modules/acti-swap/offers/OffersLists/OffersSent";
import { OfferDetails } from "modules/acti-swap/offers/OfferDetails";
import { OffersLists } from "modules/acti-swap/offers/OffersLists";
import { FullScreenLayout } from "../../layouts/FullScreenLayout";

const Home = RoutesLoader(lazy(() => import("pages/home")));
const Swap = RoutesLoader(lazy(() => import("pages/acti-swap/swap")));
const Offers = RoutesLoader(lazy(() => import("pages/acti-swap/offers")));

// const OfferDetails = RoutesLoader(lazy(() => import("pages/acti-swap/offer-details")));
const CreateAuction = RoutesLoader(lazy(() => import("pages/create-auction")));
const MintNFTPage = RoutesLoader(lazy(() => import("pages/acti-studio/mint-nft")));
const CreateCollection = RoutesLoader(lazy(() => import("pages/acti-studio/create-collection")));
const TokenSwap = RoutesLoader(lazy(() => import("pages/token-swap")));
const Roadmap = RoutesLoader(lazy(() => import("pages/roadmap")));
const LearningPortal = RoutesLoader(lazy(() => import("pages/learning-portal")));
const Error = RoutesLoader(lazy(() => import("pages/error")));

export const homeRoute: RouteObject = {
  path: "/",
  element: <FullScreenLayout />,
  children: [{ path: "", element: <Home /> }],
};

export const actiswapRoute: RouteObject = {
  path: "/acti-swap",
  element: <FullScreenLayout withFooter={false} isProtected />,
  children: [
    {
      path: "",
      element: <Navigate to="swap" replace />,
    },
    { path: "swap", element: <Swap /> },

    {
      path: "offers",
      element: <Offers />,
      children: [
        {
          path: "",
          element: <Navigate to="lists" replace />,
        },
        {
          path: "lists",
          element: <OffersLists />,
          children: [
            {
              path: "",
              element: <Navigate to="received" replace />,
            },
            {
              path: "received",
              element: <OffersReceived />,
            },
            {
              path: "sent",
              element: <OffersSent />,
            },
          ],
        },
        {
          path: ":id",
          element: <OfferDetails />,
        },
      ],
    },
  ],
};

export const actistudioRoute: RouteObject = {
  path: "/acti-studio",
  element: <FullScreenLayout withFooter={false} isProtected />,
  children: [
    {
      path: "",
      element: <Navigate to="mint-nft" replace />,
    },
    { path: "mint-nft", element: <MintNFTPage /> },
    { path: "create-collection", element: <CreateCollection /> },
  ],
};

export const tokenSwapRoute: RouteObject = {
  path: "/swap",
  element: <FullScreenLayout />,
  children: [{ path: "/swap", element: <TokenSwap /> }],
};

export const roadmapRoute: RouteObject = {
  path: "/roadmap",
  element: <FullScreenLayout />,
  children: [{ path: "/roadmap", element: <Roadmap /> }],
};

export const learningPortalRoute: RouteObject = {
  path: "/learning-portal",
  element: <FullScreenLayout />,
  children: [{ path: "/learning-portal", element: <LearningPortal /> }],
};

export const createAuctionRoute: RouteObject = {
  path: "/create-auction",
  element: <FullScreenLayout withFooter={false} />,
  children: [{ path: "/create-auction", element: <CreateAuction /> }],
};

export const errorRoute: RouteObject = {
  path: "*",
  element: <Error />,
};
