import React, { Suspense } from "react";
import { Box, CircularProgress } from "@mui/material";
import TopBarProgress from "react-topbar-progress-indicator";

const Loader = () => (
  <>
    <TopBarProgress />
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  </>
);

export const RoutesLoader = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
