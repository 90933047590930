interface Loading {
  IDLE: string;
  PENDING: string;
  SUCCEEDED: string;
  FAILED: string;
}

export const LOADING: Loading = {
  IDLE: "idle",
  PENDING: "pending",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
};
