import * as React from "react";
import { Typography, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { finalizeAuction } from "features/auctions";
import { indexAuction } from "services/auctions/auctionService";
import { useAppSelector, useAppDispatch } from "features/store";
import { TextSmallSecondary } from "components/Typography";
import { LOADING } from "app/utils/constants/others";

interface Props {
  isOwner: boolean;
  isEnded: boolean;
  auctionAddress: string;
  id: string;
}

export const FinalizeAuction = (props: Props) => {
  const { isOwner, isEnded, auctionAddress } = props;
  const navigate = useNavigate();
  const state = useAppSelector((s) => s.auctions);

  const dispatch = useAppDispatch();

  const handleFinalize = async (): Promise<void> => {
    await dispatch(finalizeAuction(auctionAddress));
    await indexAuction(auctionAddress);
    navigate(`/marketplace`);
    window.location.reload();
  };

  return (
    <Box sx={{ mt: 4, p: 2, borderRadius: "12px", backgroundColor: "background.default" }}>
      <Box mb={2}>
        <Typography gutterBottom variant="subtitle2">
          Close Auction
        </Typography>
        <TextSmallSecondary>
          When due date is reached everyone can close the auction. Auction creator can close the
          auction at any time. As long the auction hasn&apos;t been closed, bids will still be
          accepted.
        </TextSmallSecondary>
      </Box>
      <LoadingButton
        fullWidth
        loading={state.loading.finalizeAuction === LOADING.PENDING}
        onClick={handleFinalize}
        color="secondary"
        variant="outlined"
        disabled={isOwner ? false : !isEnded}
      >
        Finalize
      </LoadingButton>
    </Box>
  );
};
