import * as React from "react";
import { Box, Typography, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { retractBid, getPlacedBid, listMyAuctions } from "features/auctions";
import { useAppSelector, useAppDispatch } from "features/store";
import { LOADING } from "app/utils/constants/others";
import { TextSmallSecondary } from "components/Typography";

interface Props {
  auctionAddress: string;
}

export const RetractBid = (props: Props) => {
  const { auctionAddress } = props;

  const state = useAppSelector((s) => s.auctions);
  const dispatch = useAppDispatch();

  const submitRetractBid = async () => {
    await dispatch(retractBid(auctionAddress));
    dispatch(listMyAuctions());
  };

  React.useEffect(() => {
    const getBids = async () => {
      dispatch(getPlacedBid(auctionAddress));
    };
    getBids();
  }, [state.loading.placeBid, auctionAddress, dispatch]);

  if (!state.placedBid) return null;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        border: "1px solid  #b4eda0",
        p: 1,
        mt: 2,
        borderRadius: "8px",
      }}
    >
      <Box>
        <Stack direction="row" alignItems="center" gap={1} mb={1}>
          <TextSmallSecondary>Placed bid : </TextSmallSecondary>

          <Typography color="green">{state.placedBid.amountBid} sSCRT</Typography>
        </Stack>
        <TextSmallSecondary>{state.placedBid.message}</TextSmallSecondary>
      </Box>

      <LoadingButton
        loading={state.loading.retractBid === LOADING.PENDING}
        onClick={submitRetractBid}
        color="secondary"
        variant="outlined"
        sx={{
          height: "36px",
        }}
      >
        Retract
      </LoadingButton>
    </Stack>
  );
};
