import React, { useState } from "react";
import { Tooltip, Box } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { bech32 } from "app/utils/constants";
import { TextSmallSecondary } from "components/Typography";

interface Props {
  text: string;
  length?: number;
}

const CopiableAddress = (props: Props) => {
  const { text, length = 14 } = props;
  const [copied, setCopied] = useState(false);
  const onCopy = () => setCopied(true);

  if (!text) return null;

  return (
    <CopyToClipboard onCopy={onCopy} text={text}>
      <Tooltip title={copied ? "Copied" : "Copy"} placement="bottom">
        <Box component="span" display="flex" gap={1} sx={{ cursor: "pointer" }}>
          <ContentCopyIcon sx={{ fontSize: "16px", color: "text.secondary" }} />
          <TextSmallSecondary>{bech32(text, length)}</TextSmallSecondary>
        </Box>
      </Tooltip>
    </CopyToClipboard>
  );
};

export default CopiableAddress;
