import React from "react";
import { Box } from "@mui/material";
import { NFT } from "./NFT";

export const OfferedNFTs = ({ nfts }: { nfts: Array<any> }) => {
  return (
    <Box
      display="flex"
      alignItems="start"
      flexWrap="wrap"
      sx={{ height: "calc(70vh - 48px)", overflowY: "auto", p: 2, gap: 1 }}
    >
      {nfts.map((nft) => (
        <NFT key={nft.token_id} nft={nft} received />
      ))}
    </Box>
  );
};
